import { ConsumptionService } from "./Service";
import { getRealBuildingId } from "./Simulation";
import moment from "moment";
import { getStatus } from "../utils/Formatter";

const getCurrentTabIsEnergy = () => {
  const currentTab = localStorage.getItem("CurrentTab");
  return currentTab === "energy";
};

export const getConsumptionData = async (
  buildingId,
  start,
  end,
  aggregationType
) => {
  let params = {
    dimension: "BALANCE",
    start: start,
    end: end,
    reallocate: true,
    aggregationType: aggregationType
  };
  let response = await ConsumptionService.get(
    "withoutauth/building/" + getRealBuildingId(buildingId) + "/statistics",
    {
      params: params
    }
  );
  return response;
};

export const getConsumptionChartData = async (buildingId, timeType, date) => {
  if (!getCurrentTabIsEnergy()) return [];

  let start = moment();
  let end = moment();
  if (timeType === "today") {
    if (moment(date).format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")) {
      start = moment()
        .minute(0)
        .second(0)
        .millisecond(0)
        .subtract(1, "days");
      end = moment()
        .minute(0)
        .second(0)
        .millisecond(0)
        .subtract(1, "seconds");
    } else {
      start = moment(date).startOf("day");
      end = moment(date).endOf("day");
    }
  } else {
    if (moment(date).format("MM-YYYY") === moment().format("MM-YYYY")) {
      start = moment()
        .subtract(1, "months")
        .startOf("day");
      end = moment();
    } else {
      start = moment(date).startOf("month");
      end = moment(date).endOf("month");
    }
  }
  let response = await getConsumptionData(
    buildingId,
    start.toDate().getTime(),
    end.toDate().getTime(),
    timeType === "today" ? "HOUR" : "DAY"
  );

  return filterToChartData(response.data);
};

export const getLastDemand = async buildingId => {
  if (!getCurrentTabIsEnergy()) return 0;

  let response = await ConsumptionService.get(
    "withoutauth/building/" +
      getRealBuildingId(buildingId) +
      "/demand/last_n_minutes",
    {
      params: { nMinutes: 5 }
    }
  )
    .then(res => res.data)
    .catch(() => ({
      averageSum: 0,
      averageCount: 0,
      actualSum: 0,
      actualCount: 0
    }));
  return response;
};

export const getConsumptionCompare = async (buildingId, timeType, date) => {
  if (!getCurrentTabIsEnergy()) return [];

  let startDate = moment(date)
    .millisecond(0)
    .seconds(0)
    .minute(0);
  let params = {
    dimension: "BALANCE",
    start: moment(date)
      .subtract(1, "months")
      .toDate()
      .getTime(),
    end: startDate.toDate().getTime(),
    reallocate: true,
    aggregationType: timeType === "today" ? "HOUR" : "DAY"
  };

  let response = await ConsumptionService.get(
    "withoutauth/building/" + getRealBuildingId(buildingId) + "/statistics",
    {
      params: params
    }
  )
    .then(res =>
      getComparationData(
        res.data,
        startDate.subtract(1, "hours"),
        timeType,
        date
      )
    )
    .catch(() => ({
      averageSum: 0,
      averageCount: 0,
      actualSum: 0,
      actualCount: 0
    }));
  return response;
};

export const getConsumptionDataByTimeType = async (
  buildingId,
  timeType,
  date
) => {
  if (!getCurrentTabIsEnergy()) return 0;

  let start = moment();
  let end = moment();
  if (timeType === "today") {
    start = moment(date).startOf("day");
    end = moment(date).endOf("day");
  } else {
    start = moment(date).startOf("month");
    end = moment(date).endOf("month");
  }
  let response = await getConsumptionData(
    buildingId,
    start.toDate().getTime(),
    end.toDate().getTime(),
    timeType === "today" ? "HOUR" : "DAY"
  );

  const data = response.data;
  const lastElement = data[data.length - 1];
  const status = getStatus(lastElement?.status);

  return { total: sumMeasurementData(response.data), status };
};

const filterToChartData = data => {
  return data.map(measurement => [measurement.timestamp, measurement.value]);
};

const sumMeasurementData = data => {
  let sum = 0;
  data.forEach(measurement => (sum += measurement.value));
  return sum;
};

const getComparationData = (data, hourToCompare, timeType, date) => {
  let comparationData = {
    averageSum: 0,
    averageCount: 0,
    actualSum: 0,
    actualCount: 0
  };
  data.forEach(measurement => {
    let measurementDate = moment(measurement.timestamp);
    if (
      (timeType === "today"
        ? moment(measurement.timestamp).hour() === hourToCompare.hour()
        : moment(measurement.timestamp).day() === hourToCompare.day()) &&
      measurementDate.format("MM-DD-YYYY") !== moment(date).format("MM-DD-YYYY")
    ) {
      comparationData.averageSum += measurement.value;
      comparationData.averageCount++;
    } else if (
      (timeType === "today"
        ? moment(measurement.timestamp).hour() === hourToCompare.hour()
        : moment(measurement.timestamp).day() === hourToCompare.day()) &&
      measurementDate.format("MM-DD-YYYY") === moment(date).format("MM-DD-YYYY")
    ) {
      comparationData.actualSum += measurement.value;
      comparationData.actualCount++;
    }
  });
  let response = {
    actual:
      comparationData.actualCount > 0
        ? parseFloat(
            (comparationData.actualSum / comparationData.actualCount).toFixed(2)
          )
        : 0,
    average:
      comparationData.averageCount > 0
        ? parseFloat(
            (comparationData.averageSum / comparationData.averageCount).toFixed(
              2
            )
          )
        : 0,
    hour: hourToCompare
  };
  return response;
};
