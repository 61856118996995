import React from "react";
import { ListGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import Label from "../Label/Label";
import "./AreaList.scss";

const AreaList = ({
  children,
  type,
  title,
  timeType,
  subtitle,
  onItemClick,
  detailed,
  onTitleClick,
  fontType,
  consumptionDay,
  consumptionMonth,
  filteredTotalConsumption
}) => {
  const WidgetTitle = () => (
    <ListGroup.Item
      onClick={onTitleClick}
      title="Clique para mais informações"
      style={{ position: "sticky", top: "0" }}
    >
      <div className="thead">
        <div className="list-head-name title">Ponto de medição</div>
        <div className="list-head-value title">
          {" "}
          Consumo {timeType === "today" ? "dia" : "mês"}{" "}
          {fontType === "water" ? "(m³)" : "(kWh)"}
        </div>
      </div>
    </ListGroup.Item>
  );

  const TableTitle = () => (
    <ListGroup.Item style={{ cursor: "default", position: "sticky", top: "0" }}>
      <div className="thead table-head">
        <div className="table-head-name title">
          <div className="a"></div>
          <div className="b">Ponto de medição</div>
        </div>
        <div className="list-head-value title">Consumo dia (kWh)</div>
        <div className="list-head-value title">Consumo mês (kWh)</div>
      </div>
    </ListGroup.Item>
  );

  const TotalValueDetailed = ({ filteredTotalConsumption }) => (
    <ListGroup.Item style={{ cursor: "default", position: "sticky", top: "0" }}>
      <div className="thead table-head">
        <div className="table-head-name">
          <div className="a"></div>
          <div className="b">Consumo total estimado</div>
        </div>
        <div className="list-head-value title">
          {filteredTotalConsumption?.day.toFixed(2)}
        </div>
        <div className="list-head-value title">
          {filteredTotalConsumption?.month.toFixed(2)}
        </div>
      </div>
    </ListGroup.Item>
  );

  const TotalValue = ({ consumptionDay, consumptionMonth }) => (
    <ListGroup.Item
      title="Clique para mais informações"
      style={{ position: "sticky", top: "0" }}
    >
      <div className="thead" onClick={onTitleClick}>
        <div className="list-head-name title">Consumo total estimado</div>
        <div className="list-head-value title">
          {timeType === "today"
            ? consumptionDay?.toFixed(2)
            : consumptionMonth?.toFixed(2)}
        </div>
      </div>
    </ListGroup.Item>
  );

  const WidgetLabel = () =>
    detailed ? null : (
      <span>
        <Label
          onClick={onTitleClick}
          title={title}
          type={type}
          subtitle={subtitle}
        />
      </span>
    );

  return (
    <div className="area-list-container">
      <ListGroup className={type}>
        {detailed ? <TableTitle /> : <WidgetTitle />}
        {children &&
          children.map((child, index) => (
            <ListGroup.Item
              key={index}
              className={`${type} area-list-item`}
              onClick={() => onItemClick(child)}
            >
              {child}
            </ListGroup.Item>
          ))}
        {detailed ? (
          <TotalValueDetailed
            filteredTotalConsumption={filteredTotalConsumption}
          />
        ) : (
          <TotalValue
            consumptionDay={consumptionDay}
            consumptionMonth={consumptionMonth}
          />
        )}
      </ListGroup>
      <WidgetLabel />
    </div>
  );
};

AreaList.propTypes = {
  children: PropTypes.array,
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onItemClick: PropTypes.func
};

export default AreaList;
