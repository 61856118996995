import { create } from "zustand";

const useAlertFiltersStore = create(set => ({
  filters: {
    type: ["WARNING", "NOTICE", "ACTING"],
    status: ["read", "unread", "not-archived"],
    mitigated: ["mitigated", "not-mitigated"],
    trigger: [{ label: "Todos", field: "all" }]
  },
  setFilters: filters => set({ filters })
}));

export default useAlertFiltersStore;
