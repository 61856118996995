const filterOptions = {
  order: [
    {
      label: "Alfabética",
      field: "alphabetical"
    },
    {
      label: "Alfabética Invertida",
      field: "reverse-alphabetical"
    }
  ]
};

const UserFilters = [
  {
    label: "Ordem",
    field: "order",
    options: filterOptions["order"],
    multipleOptions: false
  }
];

export default UserFilters;
