export const getGeometries = feature => {
  return feature.geometry.type === "Polygon"
    ? [feature.geometry]
    : feature.geometry.type === "GeometryCollection"
    ? feature.geometry.geometries
    : [];
};

export const getCoordinates = feature => {
  if (feature.geometry.type === "GeometryCollection") {
    const midBuildingIndex = Math.floor(feature.geometry.geometries.length / 2);
    return feature.geometry.geometries[midBuildingIndex].coordinates;
  } else {
    return feature.geometry.coordinates;
  }
};
