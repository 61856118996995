/* Auth Actions */
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

/* WiFi Networks Actions */
export const FETCH_ACCESS_POINTS = "FETCH_ACESS_POINTS";
export const EDIT_ACCESS_POINT = "EDIT_ACCESS_POINT";
export const ADD_ACCESS_POINT = "ADD_ACCESS_POINT";
export const REMOVE_ACCESS_POINT = "REMOVE_ACCESS_POINT";
export const FETCH_BUILDINGS = "FETCH_BUILDINGS";

/* Data Actions */
export const REQUEST_ALL_DATA = "REQUEST_ALL_DATA";
export const ALL_DATA_SUCCESS = "ALL_DATA_SUCCESS";
export const ALL_DATA_FAILURE = "ALL_DATA_SUCCESS";
export const REQUEST_BUILDING_DATA = "REQUEST_BUILDING_DATA";
export const BUILDING_DATA_SUCCESS = "BUILDING_DATA_SUCCESS";
export const BUILDING_DATA_FAILURE = "BUILDING_DATA_FAILURE";

/* MobSense Actions */
export const FETCH_SENSORS = "FETCH_SENSORS";
