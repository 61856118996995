import { EquatorialEnergyDetailedMeasurementFiltersMapping } from "../EquatorialEnergyDetailedMeasurementFilters";
import useSelectedMeasurementStore from "../stores/useSelectedMeasurementStore";

const {
  irms: { accessor: IRMS_ACCESSOR },
  reactive_power: { accessor: REACTIVE_POWER_ACCESSOR },
  active_power: { accessor: ACTIVE_POWER_ACCESSOR },
  power_factor: { accessor: POWER_FACTOR_ACCESSOR },
  vrms: { accessor: VRMS_ACCESSOR }
} = EquatorialEnergyDetailedMeasurementFiltersMapping;

const useGetMeasurementUtils = () => {
  const selectedMeasurement = useSelectedMeasurementStore(
    state => state.selectedMeasurement
  );

  const isIRMS = selectedMeasurement.accessor === IRMS_ACCESSOR;
  const isReactivePower =
    selectedMeasurement.accessor === REACTIVE_POWER_ACCESSOR;
  const isActivePower = selectedMeasurement.accessor === ACTIVE_POWER_ACCESSOR;
  const isPowerFactor = selectedMeasurement.accessor === POWER_FACTOR_ACCESSOR;
  const isVRMS = selectedMeasurement.accessor === VRMS_ACCESSOR;

  const hasNeutralValue = isIRMS;
  const hasTotalValue = isReactivePower || isActivePower || isPowerFactor;

  const shouldHideDecimalsWhenValueIsAbove1000 =
    isActivePower || isReactivePower;
  const shouldDivideBy1000 = isActivePower || isReactivePower;

  return {
    isIRMS,
    isReactivePower,
    isActivePower,
    isPowerFactor,
    isVRMS,
    hasNeutralValue,
    hasTotalValue,
    shouldHideDecimalsWhenValueIsAbove1000,
    shouldDivideBy1000
  };
};

export default useGetMeasurementUtils;
