import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./FAQItemList.scss";

const FAQItemList = ({ item }) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className={opened ? "faq-item opened" : "faq-item"}>
      <div className="faq-item-line" onClick={() => setOpened(!opened)}>
        <Row className="faq-row">
          <Col xs={3} sm={1} className="faq-item-header question">
            P.
          </Col>
          <Col xs={9} sm={11} className="faq-item-question">
            {item.question}
          </Col>
        </Row>
      </div>
      <div className="faq-item-answer-wrapper">
        <Row className="faq-item-answer">
          <Col xs={3} sm={1} className="faq-item-header answer">
            R.
          </Col>
          <Col xs={9} sm={11} className="faq-item-answer-paragraph">
            {item.answer}
            {item.link && (
              <>
                <br />
                <br />
                <a href={item.link}>
                  {" "}
                  {item.linkLabel ? item.linkLabel : item.link}{" "}
                </a>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FAQItemList;
