import React from "react";
import PropTypes from "prop-types";
import "./Hexagon.scss";

const Hexagon = ({ consumption, type, outlined, unity }) => {
  let outlinedClass = outlined ? " outlined" : "";

  return (
    <div className={"hex " + type + outlinedClass}>
      <div className="hex-value">
        <div className="hex-demand">{consumption}</div>
        <div className="hex-unity">{unity}</div>
      </div>
    </div>
  );
};

Hexagon.propTypes = {
  unity: PropTypes.string,
  consumption: PropTypes.string,
  type: PropTypes.string
};

export default Hexagon;
