import React from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faToggleOff,
  faTrash,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { removeRule, setRuleEnabled } from "../../api/Rules";
import swal from "@sweetalert/with-react";

import "./EditRulesModal.scss";

const EditRulesModal = ({ show, onHide, rules, setRules, setRuleToUpdate }) => {
  const handleToggle = async (rule, enabled) => {
    await setRuleEnabled(rule, enabled).then(ruleUpdated => {
      if (ruleUpdated) {
        const newRules = [...rules];
        const ruleIndex = newRules.findIndex(x => x.id == rule.id);
        newRules[ruleIndex].enabled = enabled;
        setRules(newRules);
      } else {
        swal(
          "Ops... Algo deu errado",
          "Não foi possível atualizar a regra",
          "error"
        );
      }
    });
  };

  const handleEditRule = rule => {
    setRuleToUpdate(rule);
  };

  const deleteRule = async id => {
    await removeRule(id).then(ruleRemoved => {
      if (ruleRemoved) {
        swal("Regras removida", "A regra foi removida com sucesso.", "success");
        const newRules = [...rules];
        const ruleIndex = newRules.findIndex(rule => rule.id == id);
        newRules.splice(ruleIndex, 1);
        setRules(newRules);
      } else {
        swal(
          "Ops... Algo deu errado",
          "Não foi possível remover a regra",
          "error"
        );
      }
    });
  };

  const handleRemove = async rule => {
    swal({
      title: `Remover a regra ${rule.name}?`,
      text: `Tem certeza que deseja remover a regra ${rule.name}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(shouldRemoveRule => {
      if (shouldRemoveRule) {
        deleteRule(rule.id);
      } else {
        swal(
          `Regra não removida`,
          `A regra ${rule.name} não foi removida!`,
          "info"
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="rules-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Editar Regras
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={"rules-wrapper"}>
        <Row className="rules-list-header">
          <Col md={9} className="rules-column-header">
            {" "}
            Nome da Regra{" "}
          </Col>
          <Col md={1} className="rules-column-header">
            {" "}
            Habilitar{" "}
          </Col>
          <Col md={1} className="rules-column-header">
            {" "}
            Editar{" "}
          </Col>
          <Col md={1} className="rules-column-header">
            {" "}
            Excluir{" "}
          </Col>
        </Row>
        {rules.length === 0 && (
          <div className="no-rules-header"> Não há regras </div>
        )}
        {rules.length > 0 &&
          rules.map((rule, index) => (
            <Row
              key={index}
              className={"rule-header " + (rule.enabled ? "on" : "off")}
            >
              <Col md={9} className={"text"}>
                {rule.name}
              </Col>
              <Col md={1} className={"switcher"}>
                <FontAwesomeIcon
                  icon={rule.enabled ? faToggleOn : faToggleOff}
                  size="2x"
                  onClick={() => handleToggle(rule, !rule.enabled)}
                />
              </Col>
              <Col md={1} className={"switcher"}>
                <FontAwesomeIcon
                  icon={faEdit}
                  size="2x"
                  onClick={() => handleEditRule(rule)}
                />
              </Col>
              <Col md={1} className={"switcher"}>
                <FontAwesomeIcon
                  icon={faTrash}
                  size="2x"
                  onClick={() => handleRemove(rule)}
                />
              </Col>
            </Row>
          ))}
      </Modal.Body>
    </Modal>
  );
};

EditRulesModal.propTypes = {
  actuator: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  rules: PropTypes.array,
  setRules: PropTypes.func
};

export default EditRulesModal;
