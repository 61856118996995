import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

import "./ObjectDetector.scss";

const ObjectDetector = ({ currentTab, onSelectArea, type, url }) => {
  useEffect(() => {
    if (currentTab === "objectDetector") {
      onSelectArea({
        name: "Guarita",
        buildingId: "GUARITA",
        ignoreMeasurements: true
      });
      setImgLoaded(true);
    }
  }, [currentTab, onSelectArea]);

  let [imgLoaded, setImgLoaded] = useState(true);

  const onError = () => {
    setImgLoaded(false);
  };

  return (
    <div className="object-detector-row">
      <Card style={{ width: "100%" }}>
        <div className="object-detector-container">
          {imgLoaded && (
            <img
              alt="Video"
              //variant="top"
              src={`http://2e21d924.ngrok.io/video/?type=${type}&url=${url}`}
              onError={onError}
            />
          )}
          {!imgLoaded && (
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/embed/tq0BgncuMhs?autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video"
              ></iframe>
            </div>
          )}
        </div>

        <Card.Body>
          <Card.Title>Detecção de Objetos</Card.Title>
        </Card.Body>
      </Card>
      <img></img>
    </div>
  );
};

ObjectDetector.propTypes = {
  currentTab: PropTypes.string,
  onSelectArea: PropTypes.func,
  type: PropTypes.string,
  url: PropTypes.string
};

export default ObjectDetector;
