import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import useForm from "react-hook-form";
import { editAccessPoint, fetchAccessPoints } from "../../../actions/wifi";
import { connect } from "react-redux";
import swal from "@sweetalert/with-react";

import "./Wifi.scss";

const EditWifiModal = ({
  point,
  show,
  onHide,
  setModalShow,
  editAccessPoint,
  fetchAccessPoints
}) => {
  const { handleSubmit, register } = useForm();

  const onSubmit = async values => {
    let data = {
      name: values.name,
      password: values.password
    };

    editAccessPoint(point.name, data)
      .then(() => {
        swal(
          "Ponto de Acesso editado",
          "Os dados foram com sucesso.",
          "success"
        ).then(() => {
          fetchAccessPoints();
          setModalShow(false);
        });
      })
      .catch(() => {
        swal(
          "Ops... Algo deu errado",
          "Não foi possível editar a rede.",
          "error"
        );
      });

    return undefined;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Editar Ponto de Acesso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="wifi-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="group">
            <input name="name" ref={register} defaultValue={point.name} />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>NOME</label>
          </div>

          <div className="group">
            <input
              name="password"
              ref={register}
              defaultValue={point.password !== "NULL" ? point.password : ""}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>SENHA</label>
          </div>
          <Button className="button" variant="primary" type="submit">
            Editar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

EditWifiModal.propTypes = {
  point: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setModalShow: PropTypes.func,
  editAccessPoint: PropTypes.func,
  fetchAccessPoints: PropTypes.func
};

export default connect(
  null,
  { editAccessPoint, fetchAccessPoints }
)(EditWifiModal);
