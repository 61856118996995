import {
  ADD_ACCESS_POINT,
  EDIT_ACCESS_POINT,
  REMOVE_ACCESS_POINT,
  FETCH_ACCESS_POINTS,
  FETCH_BUILDINGS
} from "./types";

import axios from "axios";

const Service = axios.create({
  baseURL: "https://150.165.15.97:5000",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  }
});

export const fetchAccessPoints = () => async dispatch => {
  const access_points = await Service.get("/access_points");
  const buildings = await Service.get("/buildings");

  dispatch({ type: FETCH_ACCESS_POINTS, payload: access_points.data });
  dispatch({ type: FETCH_BUILDINGS, payload: buildings.data });
};

export const editAccessPoint = (point, data) => async dispatch => {
  const response = await Service.put(`/access_points/${point}/edit`, data);

  dispatch({ type: EDIT_ACCESS_POINT, payload: response.data });
};

export const addAccessPoint = data => async dispatch => {
  const response = await Service.post("/access_points", data);

  dispatch({ type: ADD_ACCESS_POINT, payload: response.data });
};

export const removeAccessPoint = accessPoint => async dispatch => {
  const response = await Service.post("/access_points/delete", accessPoint);

  dispatch({ type: REMOVE_ACCESS_POINT, payload: response.data });
};
