import { Service } from "./Service";
import { userRegister } from "./Auth";
import { notACampusEnvList, isPMCGEnv } from "../utils/EnvUtils";
import $ from "jquery";

const campus = process.env.REACT_APP_CAMPUS;

export const getRNPUsers = async () => {
  const authData = localStorage.getItem("AuthData");
  const authToken = localStorage.getItem("AuthToken");

  let users = await Service.get("/users/rnp", {
    params: {
      campus: notACampusEnvList.includes(campus) ? undefined : campus,
      limit: null,
      offset: null,
      token: authToken ? authToken : authData,
      isPMCG: isPMCGEnv
    }
  })
    .then(res => res.data.data)
    .catch(err => {
      console.error(err);
      return [];
    });

  return users;
};

export const updateRNPUser = async (user, buildings, roles) => {
  let params = {};
  if (buildings) params.buildings = buildings;
  if (roles) params.roles = roles;

  return await Service.put("/user/rnp/" + user.id, JSON.stringify(params), {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const createRNPUser = async (firstName, lastName, email) => {
  const params = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    campus: campus,
    isPMCG: isPMCGEnv
  };
  const response = await Service.post("/user/rnp", $.param(params));
  await userRegister();
  return response;
};
