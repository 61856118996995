import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Chart from "./Chart";
import "./Chart.scss";
import moment from "moment";

const ConsumptionChart = ({ type, timeType, data, date }) => {
  const getColor = useCallback(() => {
    return type === "energy" ? "#E2AD33" : "#007d9f";
  });

  const getUnit = () => {
    return type === "energy" ? "kWh" : "L";
  };

  const getTitle = () => {
    return timeType === "today" ? "Horário" : "Dia";
  };

  const getDateFormat = useCallback(() => {
    return timeType === "today" ? "%d/%m/%Y, %H:%M" : "%d/%m/%Y";
  });

  const format = value => {
    return value + " " + getUnit();
  };

  let [options, setOptions] = useState({
    chart: {
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 0,
      style: {
        fontFamily: "Ubuntu"
      }
    },

    time: {
      useUTC: false
    },

    title: {
      text: ""
    },

    credits: false,

    yAxis: {
      title: {
        text: "CONSUMO (" + getUnit() + ")"
      },

      labels: {
        formatter: function() {
          return format(this.value);
        }
      },

      plotLines: [
        {
          value: 0,
          width: 1,
          color: "#808080"
        }
      ],

      minorTickLength: 0,
      tickLength: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",

      endOnTick: false
    },

    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 1
      },
      series: {
        pointWidth: 10
      }
    },

    tooltip: {
      enabled: true,
      xDateFormat: getDateFormat(),
      pointFormatter: function() {
        var out = "";
        out += "<b>" + this.y.toFixed(2) + " " + getUnit();
        return out;
      }
    },

    legend: {
      enabled: false
    },

    exporting: {
      enabled: false
    },

    series: [
      {
        id: "consumption",
        name: "conusmption",
        color: getColor(),
        data: data,
        type: "column"
      }
    ]
  });

  useEffect(() => {
    setOptions({
      ...options,
      xAxis: {
        type: "datetime",
        minorTickLength: 0,
        tickLength: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        title: {
          text: getTitle()
        }
      },
      tooltip: {
        enabled: true,
        xDateFormat: getDateFormat(),
        pointFormatter: function() {
          var out = "";
          out += "<b>" + this.y.toFixed(2) + " " + getUnit();
          return out;
        }
      },
      series: [
        {
          id: "consumption",
          name: "consumption",
          color: getColor(),
          data: data,
          type: "column"
        }
      ]
    });
  }, [data]);

  return (
    <div className="consumption-chart-container">
      <div className="title-container">
        <div className="chart-title">
          <div className="historic">
            Histórico de Consumo
            <div className="subtitle">
              {timeType === "today"
                ? moment(date).format("dddd")
                : moment(date).format("MMMM")}
            </div>
          </div>
        </div>
      </div>
      <div className="chart-container">
        <div className="chart-column">
          {data && data.length > 0 && <Chart options={options}></Chart>}
          {!data ||
            (data.length === 0 && (
              <div className="chart-error-text">
                Não há dados de consumo para o período
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

ConsumptionChart.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array,
  timeType: PropTypes.string
};

export default ConsumptionChart;
