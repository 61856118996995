import React, { useEffect } from "react";
import { postTracking } from "../../../api/Tracking";
import "../../Dashboard.scss";
import "./Energy.scss";
import EnergyWidgets from "./EnergyWidgets";
import EnergyDetailed from "./EnergyDetailed";

const Energy = ({ showDetailed, showAll, ...props }) => {
  useEffect(() => {
    postTracking("MAPS-HOME");
  }, []);

  const shouldDisplayDetailed = showAll && showDetailed;

  if (shouldDisplayDetailed)
    return <EnergyDetailed showAll={showAll} {...props} />;

  return <EnergyWidgets showAll={showAll} {...props} />;
};

export default Energy;
