const addSeparator = (value, separator = ".") =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const formatConsumption = value => {
  value = value > 9999 ? value / 1000 : value;
  const consumption = value ? parseFloat(value.toFixed(0)) : 0;
  return addSeparator(consumption);
};

export const getConsumptionUnit = value => (value > 9999 ? " MWh" : " kWh");

export const getDemandUnit = value => (value > 9999 ? " MW" : " kW");

export const getStatus = statusData => {
  const status = statusData?.status.toLowerCase();
  const validStatus = ["above", "normal", "below"];

  if (status && validStatus.includes(status)) {
    return status;
  }

  return "normal";
};
