import {
  REQUEST_ALL_DATA,
  ALL_DATA_SUCCESS,
  ALL_DATA_FAILURE,
  REQUEST_BUILDING_DATA,
  BUILDING_DATA_SUCCESS,
  BUILDING_DATA_FAILURE
} from "./types";
import { getAllBuildingsDashoardData } from "../api/Service";
import { getConsumptionCompare } from "../api/Consumption";
import { getActuators } from "../api/Actuators";

let getAllBuildingsDataID = 0;

export const getAllBuildingsData = (
  date,
  authorizedGeoData
) => async dispatch => {
  try {
    let actionID = getAllBuildingsDataID;
    getAllBuildingsDataID += 1;
    dispatch({ type: REQUEST_ALL_DATA });
    const payload = await getAllBuildingsDashoardData(date, authorizedGeoData);
    dispatch({ type: ALL_DATA_SUCCESS, payload, id: actionID });
  } catch (error) {
    dispatch({ type: ALL_DATA_FAILURE });
  }
};

export const getBuildingData = (
  buildingId,
  buildings,
  date
) => async dispatch => {
  const buildingss = {};
  buildings.forEach(building => (buildingss[building.buildingId] = building));
  try {
    dispatch({ type: REQUEST_BUILDING_DATA });
    const payload = buildingss[buildingId];

    let compare = {
      day: {},
      month: {}
    };

    compare.day = await getConsumptionCompare(
      payload.buildingId,
      "today",
      date
    );

    compare.month = await getConsumptionCompare(
      payload.buildingId,
      "month",
      date
    );

    payload.compare = compare;
    payload.actuators = await getActuators(payload.buildingId);

    dispatch({ type: BUILDING_DATA_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: BUILDING_DATA_FAILURE });
  }
};
