import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import useForm from "react-hook-form";
import { addAccessPoint, fetchAccessPoints } from "../../../actions/wifi";
import { connect } from "react-redux";
import swal from "@sweetalert/with-react";

import "./Wifi.scss";

const WifiModal = ({
  show,
  setModalShow,
  fetchAccessPoints,
  addAccessPoint,
  onHide,
  area
}) => {
  const { handleSubmit, register } = useForm();

  const onSubmit = async values => {
    addAccessPoint({
      name: values.name,
      password: values.password,
      local: area
    })
      .then(() => {
        swal(
          "Ponto de Acesso adicionado",
          "A rede WiFi informada foi adicionada com sucesso. Obrigado pela contribuição",
          "success"
        ).then(() => {
          setModalShow(false);
          fetchAccessPoints();
        });
      })
      .catch(() => {
        swal(
          "Ops... Algo deu errado",
          "Não foi possível adicionar a rede.",
          "error"
        );
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Adicionar Rede WiFi
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="wifi-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="group">
            <input
              name="name"
              ref={register({
                required: "Required"
              })}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>NOME</label>
          </div>

          <div className="group">
            <input name="password" ref={register} />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>SENHA</label>
          </div>
          <Button className="button" variant="primary" type="submit">
            Adicionar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

WifiModal.propTypes = {
  show: PropTypes.bool,
  setModalShow: PropTypes.func,
  fetchAccessPoints: PropTypes.func,
  addAccessPoint: PropTypes.func,
  onHide: PropTypes.func,
  area: PropTypes.string
};

export default connect(
  null,
  { addAccessPoint, fetchAccessPoints }
)(WifiModal);
