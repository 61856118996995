import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import "./TimeTypeSelector.scss";

const TimeTypeSelector = ({ timeType, onTimeTypeChange }) => {
  return (
    <ButtonGroup
      aria-label="Seletor de tempo"
      className="time-type-selector-container"
    >
      <Button
        className={timeType === "today" ? "selected" : ""}
        onClick={() => onTimeTypeChange("today")}
      >
        DIA
      </Button>
      <Button
        className={timeType === "month" ? "selected" : ""}
        onClick={() => onTimeTypeChange("month")}
      >
        MÊS
      </Button>
    </ButtonGroup>
  );
};

TimeTypeSelector.propTypes = {
  timeType: PropTypes.string.isRequired,
  onTimeTypeChange: PropTypes.func.isRequired
};

export default TimeTypeSelector;
