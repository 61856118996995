import useEquatorialUserIsAuthenticated from "../hooks/useEquatorialUserIsAuthenticated";

const ProtectedRoute = ({ children }) => {
  const equatorialUserIsAuthenticated = useEquatorialUserIsAuthenticated();

  const redirectToLogin = () => {
    window.location = "/login";
  };

  if (!equatorialUserIsAuthenticated) redirectToLogin();
  else return children;
};

export default ProtectedRoute;
