import React, { useState } from "react";
import PropTypes from "prop-types";
import AlertRow from "./AlertRow";
import Label from "../Label/Label";
import AlertModal from "../AlertListWidget/AlertModal";
import AlertFilters from "./AlertFilters";
import { ListGroup, Row, Col, Button } from "react-bootstrap";
import ListFilter from "../ListFilter/ListFilter";
import "./AlertList.scss";
import { isLoading } from "../../selectors/data";
import { useSelector } from "react-redux";
import { isEQUATORIALEnv } from "../../utils/EnvUtils";
import useAlertFiltersStore from "../../Dashboard/Tabs/AlertsTab/stores/useAlertFiltersStore";

const AlertList = ({
  alerts,
  onAlertClick,
  buildingId,
  buildings,
  isReadOnlyUser,
  triggers,
  refetch
}) => {
  const [modalShow, setModalShow] = useState(false);
  const loading = useSelector(isLoading);

  const filters = useAlertFiltersStore(state => state.filters);
  const setFilters = useAlertFiltersStore(state => state.setFilters);

  const items = alerts?.map(alert => {
    const building = buildings?.find(b => b?.buildingId === alert?.buildingId);
    return (
      <AlertRow
        alert={alert}
        key={`${alert?.id}-${alert?.buildingId}`}
        onAlertClick={onAlertClick}
        building={building}
        alerts={alerts}
        refetch={refetch}
        isReadOnlyUser={isReadOnlyUser}
      />
    );
  });

  const renderAlerts = () => {
    if (alerts?.length === 0)
      return (
        <Label
          subtitle={loading ? "Carregando alertas..." : "Não há alertas"}
        ></Label>
      );
    else
      return (
        <div className="list-alerts">
          <Row className="list-headers">
            <Col md={3}>
              <Row>Ponto de medição</Row>
            </Col>
            <Col md={6}>
              <Row>Alerta</Row>
            </Col>
            <Col md={3} className="last-header">
              Ações
            </Col>
          </Row>
          <ListGroup className="alerts">{items}</ListGroup>
        </div>
      );
  };

  const renderButton = buildingId => {
    if (buildingId) {
      return (
        <div style={{ textAlign: "right" }}>
          <Button
            id="add-alert"
            className="add-alert"
            variant="primary"
            onClick={() => setModalShow(true)}
          >
            Adicionar Alerta
          </Button>
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <div className="alert-list">
      <AlertModal
        buildingId={buildingId}
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
      <Row className="filters">
        <div className="filters-row">
          {AlertFilters(triggers)?.map((filter, index) => {
            if (isEQUATORIALEnv && filter?.field === "type") return;
            return (
              <ListFilter
                filters={filters}
                filter={filter}
                onChangeFilter={setFilters}
                key={index}
              />
            );
          })}
        </div>
        <div>{!isReadOnlyUser && renderButton(buildingId)}</div>
      </Row>
      {renderAlerts()}
    </div>
  );
};

AlertList.propTypes = {
  alerts: PropTypes.array,
  onAlertClick: PropTypes.func,
  buildings: PropTypes.array,
  isReadOnlyUser: PropTypes.bool
};

export default AlertList;
