const filterOptions = {
  type: [
    {
      label: "Advertências",
      field: "WARNING"
    },
    {
      label: "Avisos",
      field: "NOTICE"
    },
    {
      label: "Atuação",
      field: "ACTING"
    }
  ],
  status: [
    {
      label: "Lidos",
      field: "read"
    },
    {
      label: "Não lidos",
      field: "unread"
    },
    {
      label: "Não Arquivados",
      field: "not-archived"
    },
    {
      label: "Arquivados",
      field: "archived"
    }
  ],
  mitigated: [
    {
      label: "Mitigados",
      field: "mitigated"
    },
    {
      label: "Não Mitigados",
      field: "not-mitigated"
    }
  ]
};

const AlertFilters = triggers => [
  {
    label: "Tipo",
    field: "type",
    options: filterOptions["type"],
    multipleOptions: true
  },
  {
    label: "Status",
    field: "status",
    options: filterOptions["status"],
    multipleOptions: true
  },
  {
    label: "Mitigação",
    field: "mitigated",
    options: filterOptions["mitigated"],
    multipleOptions: true
  },
  {
    label: "Gatilho",
    field: "trigger",
    options: triggers,
    multipleOptions: false
  }
];

export default AlertFilters;
