import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./Bars.scss";

const Label = ({ title, value, type }) => {
  return (
    <div className="label">
      <span className="title">{title}</span>
      <span className={classNames(["value", type])}>{value}</span>
    </div>
  );
};

Label.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  type: PropTypes.string
};

export default Label;
