import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Label from "../Label/Label";
import UserFilters from "./UserFilters";
import { ListGroup } from "react-bootstrap";
import ListFilter from "../ListFilter/ListFilter";
import UserRow from "./UserRow";
import "./UserList.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const UserList = ({ users, buildings }) => {
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchUser, setSearchUser] = useState("");

  const [filters, setFilters] = useState({
    order: ["alphabetical"]
  });

  useEffect(() => {
    setFilteredUsers(users.sort(sortByName));
  }, [users]);

  const sortByName = (x, y) =>
    x.firstName + x.lastName < y.firstName + y.lastName
      ? -1
      : x.firstName + x.lastName > y.firstName + y.lastName
      ? 1
      : 0;

  useEffect(() => {
    let data = [...users];

    if (searchUser !== "") {
      data = data.filter(user =>
        (user.firstName + " " + user.lastName)
          .toLowerCase()
          .includes(searchUser.toLowerCase())
      );
    }

    if (!filters.order.includes("reverse-alphabetical")) data.sort(sortByName);
    else data.sort(sortByName).reverse();

    setFilteredUsers(data);
  }, [filters, searchUser]);

  const items = filteredUsers.map(user => {
    return <UserRow user={user} key={user.email} buildings={buildings} />;
  });

  const renderUsers = () => {
    if (filteredUsers.length === 0)
      return <Label title={"Não há usuários"}></Label>;
    else return <ListGroup className="users">{items}</ListGroup>;
  };

  return (
    <div className="user-list">
      <section className="filters">
        {UserFilters.map((filter, index) => (
          <div className="order-filter" key={index}>
            <ListFilter
              filters={filters}
              filter={filter}
              onChangeFilter={setFilters}
            />
          </div>
        ))}
        <div className="list-search">
          <label className="list-search-label">
            <FontAwesomeIcon icon={faSearch} className="list-search-icon" />
            <input
              className="list-search-input"
              name="search-user"
              type="text"
              value={searchUser}
              onChange={e => setSearchUser(e.target.value)}
            />
          </label>
        </div>
      </section>
      <section className="user-list-header">
        <div className="user">Usuário</div>
        <div className="authorization">Autorizações</div>
        <div className="edit">Editar</div>
      </section>
      {renderUsers()}
    </div>
  );
};

UserList.propTypes = {
  area: PropTypes.object,
  users: PropTypes.array,
  allAreasData: PropTypes.array,
  buildings: PropTypes.array
};

export default UserList;
