import React, { useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "./DateWidget.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DateWidget = ({ type, timeType, date, setDate }) => {
  let calendar = useRef();
  registerLocale("pt-BR", ptBR);
  return (
    <div className="date-widget-container">
      <div
        className="label"
        onClick={() => {
          calendar.setOpen(true);
        }}
      >
        <FontAwesomeIcon className="date-widget-title" icon={faCalendar} />
        <div className={`date-widget-value ${type}`}>
          {timeType === "today"
            ? moment(date).format("DD/MM")
            : moment(date).format("MMMM")}
        </div>
      </div>

      <DatePicker
        ref={c => (calendar = c)}
        style={{ width: 10 }}
        showPopperArrow={false}
        selected={date}
        onChange={setDate}
        locale={"pt-BR"}
      ></DatePicker>
    </div>
  );
};

DateWidget.propTypes = {
  type: PropTypes.string,
  timeType: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  setDate: PropTypes.func,
  showAll: PropTypes.bool
};

export default DateWidget;
