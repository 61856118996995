import useEquatorialTableDataStore from "../stores/useEquatorialTableDataStore";
import useSelectedMeasurementStore from "../stores/useSelectedMeasurementStore";

const useGetDataForSelectedMeasurement = () => {
  const equatorialTableData = useEquatorialTableDataStore(
    state => state.equatorialTableData
  );

  const selectedMeasurement = useSelectedMeasurementStore(
    state => state.selectedMeasurement
  );

  return equatorialTableData?.map(area => ({
    ...area,
    buildingId: area?.buildingId,
    name: area?.name,
    value: area[selectedMeasurement.accessor]?.value,
    phases: area[selectedMeasurement.accessor]?.phases,
    status: area?.status || "normal",
    timestamp: area[selectedMeasurement.accessor]?.timestamp
  }));
};

export default useGetDataForSelectedMeasurement;
