import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ActuatorRow from "./ActuatorRow";
import AddRulesModal from "../Rules/AddRulesModal";
import EditRulesModal from "../Rules/EditRulesModal";
import Label from "../Label/Label";
import ActuatorFilters from "./ActuatorFilters";
import { ListGroup } from "react-bootstrap";
import ListFilter from "../ListFilter/ListFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { getRules } from "../../api/Rules";
import "./ActuatorList.scss";
import { useSelector } from "react-redux";
import { isLoading } from "../../selectors/data";

const ActuatorList = ({
  area,
  actuators,
  buildings,
  onActuatorClick,
  isReadOnlyUser
}) => {
  const [filters, setFilters] = useState({
    order: ["alphabetical"],
    status: ["on", "off"],
    availability: ["on", "off"]
  });

  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [ruleToUpdate, setRuleToUpdate] = useState(undefined);

  const [rules, setRules] = useState([]);
  let loading = useSelector(isLoading);

  useEffect(() => {
    if (actuators?.length > 0) fetchRules();
  }, [actuators]);

  useEffect(() => {
    ruleToUpdate && setAddModalShow(true);
  }, [ruleToUpdate]);

  useEffect(() => {
    if (!addModalShow) setRuleToUpdate(undefined);
  }, [addModalShow]);

  const fetchRules = async () => {
    await getRules(actuators).then(rules => setRules(rules));
  };

  const addRuleWrapper = () => {
    setRuleToUpdate(undefined);
    setAddModalShow(!editModalShow);
  };

  const items = area?.buildingId
    ? actuators?.map(actuator => {
        return (
          <ActuatorRow
            actuator={actuator}
            key={
              actuator.hardware_id +
              "-" +
              (actuator.switch_state ? "on" : "off")
            }
            onActuatorClick={onActuatorClick}
            isReadOnlyUser={isReadOnlyUser}
          />
        );
      })
    : buildings.map((building, index) => {
        let buildingActuators = actuators.filter(
          actuator => actuator.building_id.$oid === building.buildingId
        );
        if (buildingActuators.length > 0) {
          return (
            <div key={index}>
              <div className="building-title">{building.name}</div>
              {buildingActuators.map(actuator => (
                <ActuatorRow
                  actuator={actuator}
                  key={actuator.hardware_id}
                  onActuatorClick={onActuatorClick}
                  isReadOnlyUser={isReadOnlyUser}
                />
              ))}
            </div>
          );
        }
      });

  const renderActuators = () => {
    if (actuators?.length === 0)
      return (
        <Label
          title={loading ? "Carregando atuadores..." : "Não há atuadores"}
        ></Label>
      );
    else return <ListGroup className="actuators">{items}</ListGroup>;
  };

  const getActuatorListKey = () => {
    let key = "actuators";
    if (actuators) {
      key += "-";
      const onActuators = actuators.filter(x => x.switch_state).length;
      const offActuators = actuators.filter(x => !x.switch_state).length;
      key += onActuators + "." + offActuators;
    }
    return key;
  };

  return (
    <div className="actuator-list" key={getActuatorListKey()}>
      <AddRulesModal
        actuators={actuators}
        show={addModalShow}
        onHide={() => setAddModalShow(false)}
        editRule={ruleToUpdate}
        setRuleToUpdate={setRuleToUpdate}
      />
      <EditRulesModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        rules={rules}
        setRules={setRules}
        setRuleToUpdate={setRuleToUpdate}
      />
      <section className="filters">
        {ActuatorFilters.map((filter, index) => (
          <div key={index}>
            <ListFilter
              filters={filters}
              filter={filter}
              onChangeFilter={setFilters}
            />
          </div>
        ))}
        <div className="a" style={{ marginLeft: "auto", display: "flex" }}>
          {!isReadOnlyUser && <div className={"label"}>Regras</div>}
          {!isReadOnlyUser && (
            <div onClick={() => addRuleWrapper()}>
              <button className={"rules"}>
                <FontAwesomeIcon icon={faPlus} size="1x" />
              </button>
            </div>
          )}
          {!isReadOnlyUser && (
            <div
              className={"button"}
              onClick={() => setEditModalShow(!editModalShow)}
            >
              <button className={"rules"}>
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </button>
            </div>
          )}
        </div>
      </section>
      {renderActuators()}
    </div>
  );
};

ActuatorList.propTypes = {
  area: PropTypes.object,
  actuators: PropTypes.array,
  allAreasData: PropTypes.array,
  onActuatorClick: PropTypes.func,
  buildings: PropTypes.array,
  isReadOnlyUser: PropTypes.bool
};

export default ActuatorList;
