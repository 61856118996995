import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import "./Logout.scss";
import { connect } from "react-redux";
import { signOut } from "../../actions/auth";

const Logout = ({ signOut }) => {
  return (
    <div className="login">
      <Container>
        <h2>AGUARDE UM INSTANTE...</h2>
        {signOut()}
      </Container>
    </div>
  );
};

Logout.propTypes = {
  signIn: PropTypes.func
};

export default connect(
  null,
  { signOut }
)(Logout);
