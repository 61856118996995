const fundamentalsData = [
  {
    question: `O que é energia?`,
    answer: `É a capacidade de realizar algum trabalho, é medida em joules (J)`
  },
  {
    question: `Quais as formas de energia?`,
    answer: `- Mecânica (cinética, potencial, elástica) 
        - Elétrica (cargas elétricas em um campo)
        - Química (alterações químicas - oxidação)
        - Térmica (variação de temperatura, radiação)
        - Nuclear`
  },
  {
    question: `O que é potência?`,
    answer: `A potência ativa (P) é a velocidade com que um trabalho é realizado, isto é, a razão entre o valor (em Joules) para realização do trabalho dividido pelo tempo (em segundos) para realizá-lo. Nessa lógica, a unidade de medida da potência ativa é o J/s (Joule por segundo), que é numericamente equivalente a unidade de medida Watts (W).

        Além da potência ativa, temos a potência reativa (Q). Essa é a potênciaque fica armazenada no campo elétrico e que não é capaz de realizar trabalho. Assim como a potência ativa, ela é passada pelos condutores da rede elétrica, mas não é convertida em outras formas de energia para realização de trabalhos necessários.
        
        Podemos ainda combinar essas duas potências e obter a potência aparente (S). Essa é a potência total que percorre os cabos da rede, causando perdas pelo efeito Joule.`
  },
  {
    question: `Quais são os tipos de potência?`,
    answer: `Potência ativa(P): potência útil, gera trabalho
        Potência reativa(Q): armazenada no campo elétrico ou magnético (entra e sai do sistema)
        Potência aparente(S): percorre os cabos causando as perdas por efeito joule (por isso a reativa importa)`
  },
  {
    question: `O que é tensão?`,
    answer: `A Tensão Elétrica, ou DDP (Diferança de Potencial) é a grandeza elétrica que mede a diferença de potêncial elétrico entre dois pontos. Ela é responsável pela ordenação das cargas livres (o que gera a corrente elétrica), pois os elétrons tendem a sair do ponto de maior potencial elétrico para o de menor, gerando um movimento ordenado. A unidade de medida dessa grandeza é o Volt (V).`
  },
  {
    question: `O que é lúmen?`,
    answer: `Lúmen é a unidade de medida de fluxo luminoso no Sistema Internacional. De modo mais prático, podemos entender um lúmen como sendo a quantidade de luz que é emitida por uma lâmpada e nossos olhos conseguem perceber.`
  }
];

export default fundamentalsData;
