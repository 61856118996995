const termsAndConditionsData = [
  {
    question: `Onde posso encontrar os Termos de Uso?`,
    answer: `Para ter acesso aos Termos de Uso do LiteCampus, acesse o link abaixo.`,
    link: `https://liteme.com.br/litecampus/uso`,
    linkLabel: `Termos de Uso do LiteCampus`
  },
  {
    question: `Onde posso encontrar a Política de Privacidade?`,
    answer: `Para ter acesso à Política de Privacidade do LiteCampus, acesse o link abaixo.`,
    link: `https://liteme.com.br/litecampus/privacidade`,
    linkLabel: `Política de Privacidade do LiteCampus`
  },
  {
    question: `Onde posso encontrar a Política de Segurança?`,
    answer: `Para ter acesso à Política de Segurança do LiteCampus, acesse o link abaixo.`,
    link: `https://liteme.com.br/litecampus/seguranca`,
    linkLabel: `Política de Segurança do LiteCampus`
  }
];

export default termsAndConditionsData;
