const formatEquatorialValues = (value, decimals, shouldDivideBy1000) => {
  const valueToFormat = shouldDivideBy1000 ? (value || 0) / 1000 : value || 0;

  return valueToFormat?.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

export default formatEquatorialValues;
