const tipsData = [
  {
    question: `Como escolher a melhor lâmpada para um ambiente?`,
    answer: `Para uma análise de custo e retorno da iluminação de uma lâmpada, poderemos utilizar o seguinte cálculo:

        kWh/mes = (P * h * d)/1000, onde
        
        P = Potência em Watts para a lâmpada
        h = o total de horas diárias de uso
        d = quantidade de dias do mês que foi utilizada
        
        Multiplicando o valor obtido pelo preço da tarifa, pode-se inferir sobre o custo referente ao tipo de lâmpada escolhida. Abaixo estão listadas as potências referentes a 1100 lumens para cada tipo de lâmpada:
        
        Potência de uma lâmpada de LED: 12W
        Potência de uma lâmpada fluorescente: 20W
        Potência de uma lâmpada incandescente: 75W`
  },
  {
    question: `Quais os benefícios provenientes de boas práticas de gerência de energia?`,
    answer: `São muitos os benefícios da aplicação de práticas de gerência de energia!

        No setor residencial temos benefícios voltados ao ambiente e também ao custo da energia consumida.
        
        Para as concessionárias, além do custo de geração e da diminuição dos impactos ambientais, temos também a redução da inadimplência, o adiamento nos investimensos de infraestrutura para o aumento da capacidade de geração, visto que, como a energia passa a ser melhor utilizada, então a demanda por capacidade também será reduzida.
        
        Para comércios, a imagem vinculada a boas práticas ambientais de consumo energético pode ser benéfica para o andamento do negócio, assim como no setor industrial, além da diminuição de custos, adiamento de investimentos e custos de infraestrutura reduzidos.
        
        De modo geral, todos os setores saem ganahndo no quesito de custo, pois há o barateamento da energia com base no melhor aproveitamento da mesma com a melhoria da eficiência energética. Diretamente, o meio ambiente passa a sofrer menos danos, visto que a liberação de C02 na atmosfera por fontes de combustíveis fósseis, assim como as áreas alagadas por hidrelétricas, dentre outros pontos, serão menores tendo em vista o melhor aproveitamento da energia gerada por essas fontes.`
  },
  {
    question: `Por que adotar medidas de boas práticas de gerência de energia?`,
    answer: `Alguns pontos importantes para a adoção de boas medidas de gerência de energia são:

        - A crescente demanda por energia, isto é, a necessidade de aumentar a geração de energia pelas fontes para suprir as necessidades de cada um dos setores
        - A importância da sustentabilidade vinculada ao mercado, ao desenvolvimento sustentável e a preservação do meio ambiente
        - Obter melhor aproveitamento da energia gerada e, consequentemente, baratear os custos para seu consumo`
  },
  {
    question: `O que a tecnologia vem trazendo para auxiliar as boas práticas de gerência de energia?`,
    answer: `Com o grande avanço das TIC's (Tecnologias de Informação e Comunicação), há uma grande aplicabilidade de técnicas que envolvem o uso de sensores para capturar, medir e transmitir dados, computação na nuvem, para o processamento em tempo real dos dados captados, aproveitamento da era do Big Data para a melhoria dos softwares com o uso de Inteligência Artificial, integração de software e hardware com práticas de Internet das Coisas, tudo isso voltado para o entendimento e a otimização do consumo em diversos sensores.`
  },
  {
    question: `Qual a relação entre a qualidade da energia e a gerência?`,
    answer: `Mesmo que a transformação da energia seja de qualidade em um alto nível, como aquecer água utilizando energia elétrica, caso essa energia que foi trasnformada tenha vindo de uma fonte de menor qualidade ou tenha se perdido em boa parte durante os processos, podemos julgar que, no contexto completo a qualidade da energia não é boa.

        Desse modo, para que possamos também ter qualidade precisamos gerir a energia da melhor forma possível, visando o melhor aproveitamento em trabalho útil.`
  },
  {
    question: `Como saber a eficiência energética de um aparelho?`,
    answer: `Uma forma prática de saber sobre a eficiencia dos aparelhos que adquirimos é pela ENCE (Etiqueta Nacional de Conservação de Energia). Essa etiqueta faz parte do Programa Brasileiro de Etiquetagem e vem junto aos aparelhos, fornecendo informações acerca da tensão, do consumo em kWh/mês, tipo do equipamento, além de indicar o fabricante e a marca.
        
        Na ENCE existe um gráfico de barras bem característico, que indica as classes de eficiência energética da mais eficiente (classe A) até a mais ineficiente (classe G). Ao lado do gráfico existe uma bandeira que indica a classe a qual o aparelho em questão pertence em termos de eficiência.
        
        Observar e entender a importância e as informações dadas por essa etiqueta pode ajudar bastante na escolha de bons equipamentos para o ambiente, como ar condicionados e refrigeradores mais eficientes.`
  },
  {
    question: `O que é a ISO 50001?`,
    answer: `a ISO 50001 é uma norma de gestão de energia que estabelece um sistema de implementação de eficiência energética em industrias, comércios e empresas em geral. Essa norma é reconhecida mundialmente e tem como principal objetivo o gerenciamento de energia em busca de uma melhor integração desses setores com o meio ambiente, por meio do uso de energia limpa, diminuição da emissão de gases poluentes e melhor aproveitamento da energia elétrica.`
  },
  {
    question: `O que é a EnPI?`,
    answer: `Energy Performance Indicators ou EnPI são os indicadores de desempenho energético.

        Após o processo de auditoria e de conhecimento do funcionamento do sistema energético em uso há a coleta de dados brutos acerca do uso da energia no sistema. Assim, esses dados crus podem ser transformados em informações úteis e consumíveis por outros setores, que podem ou não estarem envolvidos diretamente com o setor que trata dos aspectos energéticos, com base em algumas métricas.
        
        No contexto de uma indústria, alguns exemplos de métricas para a geração dessas informações podem ser:
        
        -> Quantidade de energia em kWh gasta para produção de um certo volume do produto ofertado
        -> Quantidade de energia em kWh gasta para a produção de uma unidade de produto`
  },
  {
    question: `O que é EnB?`,
    answer: `Na ISO 50001, as Energy Baselines ou EnB define as referências de energia para comparação. Além dos indicadores de desempenho, a existência de pontos de referência para comparação da evolução do programa é importante, pois a informação será mais adequada de acordo com características momentâneas ou acontecimentos.

        Por exemplo, observar o consumo de energia vinculado a estação do ano é um parâmetro de comparação bem adequado quanto ao uso de alguns aparelhos de refrigeração/aquecimento, visto que em meses mais quentes há uma tendência de maior consumo que pode não estar vinculada ao insucesso do programa de eficiência energética, mas sim da necessidade do momento.
        
        Outro exemplo é o cálculo do consumo médio por pessoa, visto que, quanto maior a quantidade de pessoas em um ambiente, mais energia é consumida, se independentemente da quantidade o consumo médio por pessoa se mantém, então o programa de eficiência se mantém válido.`
  },
  {
    question: `Recomendações para redução da perda no aquecimento`,
    answer: `- Escolher a temperatura adequada para produção e capacidade do equipamento
        - Transporte adequado da carga de acordo com o layout das máquinas
        - Áreas de perda de calor no equipamento
        - Perdas de Joule fora do forno
        - Pré-aquecimento (secar carga, reaproveitamento)
        - Carregamento
        - Nível e temperatura
        - Otimização dos ciclos`
  },
  {
    question: `Recomendações para redução da perda em sistemas de refrigeração`,
    answer: `- Limpeza nas serpentinas de trocadores de calor
        - Verificação da eficiência das bombas e motores
        - Controlar o termostato para um temperatura adequada
        - Reaproveitar o calor em outros processos`
  },
  {
    question: `Recomendações para redução da perda no bombeamento de água`,
    answer: `Verificar:
        - Entrada de ar na tubulação de sucção
        - Grandes alturas de sucção (queda de rendimento e cavitação)
        - Redução concêntrica na tubulação de sucção
        - Curvas de raio curto
        - Vazamentos`
  },
  {
    question: `Recomendações para redução da perda em ventilação e ar condicionado`,
    answer: `- São tipicamente planejados para condições extremas (para o calor, portanto é necessário regular bem a temperatura para momentos mais frios)
        - Redução da velocidade do ar ou uso de pás móveis ao invés do uso de dampers (reguladores)
        - Regulação do termostato
        - Controle da reciclagem do ar interno
        - Iluminação eficiente permite ganho duplo (energia e resfriamento)`
  }
];

export default tipsData;
