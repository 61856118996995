import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import useForm from "react-hook-form";
import { registerAlert } from "../../api/Alerts";
import swal from "@sweetalert/with-react";
import moment from "moment";

import "./AlertModal.scss";

const AlertModal = ({ buildingId, show, setModalShow, onHide }) => {
  const { handleSubmit, register } = useForm();

  let [trigger, setTrigger] = useState("");

  const parseToMilliseconds = stringHour => {
    const date = moment(stringHour, "HH:mm").toDate();

    const minutes = date.getMinutes();
    const hours = date.getHours();

    const milliseconds = (hours * 60 + minutes) * 60 * 1000;

    return milliseconds;
  };

  const onSubmit = async values => {
    const alertDescriptor = {
      actions: [{ type: "EMAIL", addresses: [values.email] }],
      applianceConsumptionLimit: Number.parseFloat(
        values.applianceConsumptionLimit
      ),
      enabled: true,
      end: parseToMilliseconds(values.end),
      frequency: { type: "DAILY", data: [] },
      name: values.name,
      start: parseToMilliseconds(values.start),
      trigger: { type: values.trigger },
      typeOfDay: ["BUSINESS_DAY", "WEEKEND", "HOLIDAY"]
    };

    const registeringAlert = await registerAlert(buildingId, alertDescriptor);

    if (registeringAlert) {
      swal(
        "Alerta adicionado",
        "O alerta foi cadastrado com sucesso. Acompanhe a plataforma para verificar ocorrências inesperadas.",
        "success"
      ).then(() => {
        setModalShow(false);
      });
    } else {
      swal(
        "Ops... Algo deu errado",
        "Não foi possível adicionar o alerta",
        "error"
      );
    }
  };

  const renderSelectTrigger = () => {
    return (
      <select
        name="trigger"
        onChange={setTrigger}
        ref={register({
          required: "Required"
        })}
        className={trigger ? "" : "placeholder"}
      >
        <option value="" hidden>
          Escolha a condição em que o alerta deve ser disparado
        </option>
        <option className="validOption" value="CONSUMPTION_LIMIT_EXCEEDED">
          Limite de consumo por hora (kWh)
        </option>
      </select>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="alert-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Adicionar Alerta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="alert-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="group">
            {renderSelectTrigger()}
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>CONDIÇÃO</label>
          </div>
          <div className={trigger ? "" : "hidden"}>
            <input defaultValue={trigger} hidden />
            <div className="group">
              <input
                name="name"
                ref={register({
                  required: "Required"
                })}
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>NOME</label>
            </div>

            <div className="d-flex justify-content-center">
              <div className="group time">
                <input
                  name="start"
                  type="time"
                  ref={register({
                    required: "Required"
                  })}
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>HORÁRIO INICIAL</label>
              </div>
              <div className="group time">
                <input
                  name="end"
                  type="time"
                  ref={register({
                    required: true
                  })}
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>HORÁRIO FINAL</label>
              </div>
            </div>
            <div className="group">
              <input
                name="applianceConsumptionLimit"
                ref={register({
                  required: true
                })}
                placeholder="Por exemplo: 2,5"
                type="number"
                step="any"
                min="0.0"
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>LIMITE DE CONSUMO (EM KWH)</label>
            </div>

            <div className="group">
              <input
                name="email"
                type="email"
                ref={register({
                  required: "Required"
                })}
                placeholder="Por exemplo: dashboard@smartcampus.com"
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>E-MAIL PARA NOTIFICAÇÃO</label>
            </div>

            <Button className="button" variant="primary" type="submit">
              Adicionar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

AlertModal.propTypes = {
  buildingId: PropTypes.string,
  show: PropTypes.bool,
  setModalShow: PropTypes.func,
  onHide: PropTypes.func
};

export default AlertModal;
