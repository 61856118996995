import formatEquatorialValues from "../utils/formatEquatorialValues";
import useGetMeasurementUtils from "./useGetMeasurementUtils";

const getDecimalsThatShouldBeDisplayed = (
  value,
  shouldHideDecimalsWhenValueIsAbove1000
) => (value && shouldHideDecimalsWhenValueIsAbove1000 > 1000 ? 0 : 2);

const useFormatEquatorialValues = () => {
  const {
    shouldHideDecimalsWhenValueIsAbove1000,
    shouldDivideBy1000
  } = useGetMeasurementUtils();

  const formatValue = value =>
    formatEquatorialValues(
      value,
      getDecimalsThatShouldBeDisplayed(
        value,
        shouldHideDecimalsWhenValueIsAbove1000
      ),
      shouldDivideBy1000
    );

  return { formatValue };
};

export default useFormatEquatorialValues;
