import { isEQUATORIALEnv } from "../utils/EnvUtils";
import useIsUserSignedIn from "./useIsUserSignedIn";

const useEquatorialUserIsAuthenticated = () => {
  if (!isEQUATORIALEnv) return true;

  return useIsUserSignedIn();
};

export default useEquatorialUserIsAuthenticated;
