import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faSquare,
  faCheckSquare
} from "@fortawesome/free-solid-svg-icons";

const ListFilterItem = ({ checked, multipleOptions, option, handleChange }) => {
  let [isChecked, setIsChecked] = useState(checked);

  const handleItemChange = field => {
    setIsChecked(!isChecked);
    handleChange(field);
  };

  return (
    <Dropdown.Item
      className="item"
      onClick={() => handleItemChange(option.field)}
    >
      <FontAwesomeIcon
        style={{
          visibility: !multipleOptions && !checked ? "hidden" : "visible"
        }}
        icon={
          multipleOptions
            ? checked
              ? faCheckSquare
              : faSquare
            : faChevronRight
        }
        size="sm"
      />
      <span className="list-filter-label">{option.label}</span>
    </Dropdown.Item>
  );
};

ListFilterItem.propTypes = {
  checked: PropTypes.bool,
  multipleOptions: PropTypes.bool,
  option: PropTypes.object,
  handleChange: PropTypes.func
};

export default ListFilterItem;
