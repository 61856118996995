import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { BsFillLightningFill } from "react-icons/bs";
import { FaTemperatureHigh } from "react-icons/fa";
import "./ActuatorRow.scss";
import { updateSwitchState } from "../../api/Actuators";
import swal from "@sweetalert/with-react";
import { isNotACampus } from "../../utils/EnvUtils";

const ActuatorRow = ({ actuator, isReadOnlyUser }) => {
  const authToken = encodeURIComponent(localStorage.getItem("AuthData"));
  const pagePath = encodeURIComponent(
    `monitor@hardware_id@${actuator.hardware_id}`
  );
  const home = isNotACampus
    ? "//painel.liteme.com.br"
    : "//set.lsd.ufcg.edu.br";
  const [status, setStatus] = useState(actuator.switch_state);
  const [disabled] = useState(!actuator.online || isReadOnlyUser);
  const handleStatusChange = newState => {
    swal({
      title: `${newState ? "Ligar" : "Desligar"} medidor?`,
      text: `Tem certeza que deseja ${
        newState ? "ligar" : "desligar"
      } o medidor?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willChangeState => {
      if (willChangeState) {
        handleUpdateActuator(actuator.hardware_id, newState);
      } else {
        swal(
          `Medidor não ${newState ? "ligado" : "desligado"}`,
          `O medidor não foi ${newState ? "ligado" : "desligado"}!`,
          "info"
        );
      }
    });
  };

  const handleUpdateActuator = async (hardware_id, newState) => {
    await updateSwitchState(hardware_id, newState).then(actuatorUpdated => {
      if (actuatorUpdated) setStatus(newState);
    });
  };

  return Object.keys(actuator.data).length > 0 ? (
    <div>
      <section
        className={"actuator-header" + (disabled ? " disabled-actuator" : "")}
      >
        <div
          className={"button switcher" + (disabled ? " disabled-actuator" : "")}
          title={"Clique para ligar ou desligar o aparelho"}
        >
          <FontAwesomeIcon
            icon={status ? faToggleOn : faToggleOff}
            size="2x"
            onClick={disabled ? undefined : () => handleStatusChange(!status)}
          />
        </div>
        <div className={"text"}>
          <section
            className="actuator-description"
            onClick={() =>
              window.location.replace(
                `${home}/#/login/${actuator.building_id.$oid}/${pagePath}/${authToken}`
              )
            }
            title={
              "Clique para visualizar informações em tempo real sobre esse aparelho"
            }
          >
            {actuator.description}
          </section>
          <section className="actuator-identifier">
            {actuator.hardware_id}
          </section>
        </div>
        <div>
          <div
            className={"set-flex"}
            title={
              actuator.type.toUpperCase().includes("POW")
                ? "Potência"
                : "Temperatura"
            }
          >
            <div className={"sign"}>
              {actuator.type.toUpperCase().includes("POW") ? (
                <BsFillLightningFill />
              ) : (
                <FaTemperatureHigh />
              )}
            </div>
            <div
              className={"info"}
              onClick={() =>
                window.location.replace(
                  `${process.env.REACT_APP_ENV}/#/login/${actuator.building_id.$oid}/${pagePath}/${authToken}`
                )
              }
            >
              {actuator.data && actuator.type.toUpperCase().includes("POW")
                ? actuator.data.power > 1000
                  ? `${(actuator.data.power / 1000).toFixed(2)} kW`
                  : `${actuator.data.power} W`
                : actuator.data.currentTemperature !== undefined
                ? `${actuator.data.currentTemperature} °C`
                : "--- °C"}
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <div>
      <section
        className={"actuator-header" + (disabled ? " disabled-actuator" : "")}
      >
        <div
          className={"button switcher" + (disabled ? " disabled-actuator" : "")}
        >
          <FontAwesomeIcon
            icon={status ? faToggleOn : faToggleOff}
            size="2x"
            onClick={disabled ? undefined : () => handleStatusChange(!status)}
          />
        </div>
        <div className={"text"}>
          <section className="actuator-description">
            {actuator.description}
          </section>
          <section className="actuator-identifier">
            {actuator.hardware_id}
          </section>
        </div>
      </section>
    </div>
  );
};

ActuatorRow.propTypes = {
  actuator: PropTypes.object,
  onActuatorClick: PropTypes.func
};

export default ActuatorRow;
