import React, { useState } from "react";
import Home from "./routes/home";
import FAQ from "./routes/faq";
import "./index.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { tryAutoConnect } from "./actions/auth";
import { connect } from "react-redux";
import Login from "./components/Login/Login";
import Logout from "./components/Logout/Logout";
import Navigator from "./components/Navbar/Navbar";
import ProtectedLoginRoute from "./routes/ProtectedLoginRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const App = ({ tryAutoConnect }) => {
  const queryClient = new QueryClient();

  const validateLitemeToken = () => {
    if (!localStorage.getItem("user")) return;
    try {
      let timestamp = JSON.parse(localStorage.getItem("user"))["timestamp"];
      const eightHourInMilliseconds = 1000 * 60 * 60 * 8;
      if (new Date().getTime() - timestamp >= eightHourInMilliseconds) {
        window.location.href = "/logout";
      }
    } catch (err) {
      console.log(err);
    }
    return;
  };

  React.useEffect(() => {
    validateLitemeToken();
  });

  React.useEffect(() => {
    tryAutoConnect();
  });

  let [closed, setClosed] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh"
        }}
      >
        <Navigator closed={closed} setClosed={setClosed} />
        <Router>
          <Switch>
            <Route exact path="/">
              <ProtectedRoute>
                <Home closed={closed} />
              </ProtectedRoute>
            </Route>
            <Route exact path="/login">
              <ProtectedLoginRoute>
                <Login />
              </ProtectedLoginRoute>
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/faq">
              <FAQ />
            </Route>
          </Switch>
        </Router>
      </div>
    </QueryClientProvider>
  );
};

export default connect(null, { tryAutoConnect })(App);

export { App };
