import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import ListFilterItem from "./ListFilterItem";
import "./ListFilter.scss";

const ListFilter = ({ filters, filter, onChangeFilter }) => {
  let [multipleOptions] = useState(filter?.multipleOptions);
  let [optionsSelected, setOptionsSelected] = useState(filters[filter?.field]);

  useEffect(() => {
    onChangeFilter({ ...filters, [filter?.field]: optionsSelected });
  }, [optionsSelected]);

  const handleFilterChange = changedFilter => {
    let selectedOptions = [];
    if (!multipleOptions) {
      selectedOptions?.push(changedFilter);
    } else {
      selectedOptions = [...optionsSelected];
      if (selectedOptions?.includes(changedFilter))
        selectedOptions = selectedOptions?.filter(
          option => option !== changedFilter
        );
      else selectedOptions?.push(changedFilter);
    }
    setOptionsSelected(selectedOptions);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle className="list-filter">{filter.label}</Dropdown.Toggle>
      <Dropdown.Menu className="menu">
        {filter?.options?.map((option, index) => (
          <ListFilterItem
            key={index}
            checked={optionsSelected?.includes(option?.field)}
            multipleOptions={multipleOptions}
            option={option}
            handleChange={handleFilterChange}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ListFilter.propTypes = {
  filters: PropTypes.object,
  filter: PropTypes.object,
  onChangeFilter: PropTypes.func
};

export default ListFilter;
