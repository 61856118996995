import useEquatorialWidgetsDataStore from "../stores/useEquatorialWidgetsDataStore";
import useSelectedMeasurementStore from "../stores/useSelectedMeasurementStore";

const useAllDataForSelectedMeasurement = () => {
  const equatorialWidgetsData = useEquatorialWidgetsDataStore(
    state => state.equatorialWidgetsData
  );

  const selectedMeasurement = useSelectedMeasurementStore(
    state => state.selectedMeasurement
  );

  const dayData = equatorialWidgetsData?.day?.[selectedMeasurement?.accessor];
  const monthData =
    equatorialWidgetsData?.month?.[selectedMeasurement?.accessor];

  return {
    day: {
      series: dayData,
      current: dayData?.[dayData?.length - 1]
    },
    month: {
      series: monthData,
      current: monthData?.[monthData?.length - 1]
    }
  };
};

export default useAllDataForSelectedMeasurement;
