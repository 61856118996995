import { Service, AuthService } from "./Service";
import { serialize } from "../utils/QueryStringUtils";

export const authenticate = async queryString => {
  const queryParams = queryString.split("=", 2);
  if (queryParams.length === 2 && queryParams[0] === "confirmation") {
    try {
      const response = await AuthService.post("/", {
        authentication: queryParams[1]
      });
      const { email, userName, success, provider } = response.data;
      if (success) {
        localStorage.setItem("AuthData", queryParams[1]);
        return {
          success: true,
          email,
          userName,
          provider
        };
      }
      return {
        success: false,
        errorMessage: "Authentication failed."
      };
    } catch (err) {
      console.log(err);
      return {
        success: false,
        errorMessage: "Authentication failed."
      };
    }
  }

  return {
    success: false,
    errorMessage: "Authentication failed."
  };
};

export const authenticateLitecampus = async loginData => {
  if (loginData) {
    try {
      const queryString = serialize(loginData);
      const response = await Service.post("/auth", queryString);
      const token = response.data.token;
      const user = response.data.user;
      if (token && user) {
        localStorage.setItem("AuthToken", token);
        return {
          success: true,
          user
        };
      }
      return {
        success: false,
        errorMessage: "Authentication failed."
      };
    } catch (err) {
      console.log(err);
      return {
        success: false,
        errorMessage: "Authentication failed."
      };
    }
  }

  return {
    success: false,
    errorMessage: "Authentication failed."
  };
};

export const logout = async () => {
  try {
    await AuthService.post("/logout", {
      authentication: localStorage.getItem("AuthData")
    });
  } catch (err) {
    console.log(err);
  }
};

export const userRegister = async () => {
  try {
    await AuthService.post("/user-register", {
      authentication: localStorage.getItem("AuthData")
    });
  } catch (err) {
    console.log(err);
  }
};
