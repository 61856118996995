import { Service } from "./Service";

export const postTracking = async page => {
  const authData = localStorage.getItem("AuthData");
  const authToken = localStorage.getItem("AuthToken");

  const user = JSON.parse(localStorage.getItem("user")) || {};

  try {
    await Service.post("/tracking", null, {
      params: {
        page: page,
        email: user.userEmail,
        loggedUser: user.userEmail
      },
      headers: {
        "Access-Token": authToken ? authToken : authData,
        "Content-Type": "application/json"
      }
    });
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const postWithoutAuthTracking = async (page, buildingId) => {
  try {
    await Service.post("/withoutauth/tracking", null, {
      params: {
        page: page,
        buildingId: buildingId,
        email: null,
        loggedUser: null
      },
      headers: {
        "Content-Type": "application/json"
      }
    });
  } catch (err) {
    console.error(err);
    return [];
  }
};
