import * as React from "react";
import { Col } from "react-bootstrap";

const ReactivePowerIcon = ({ isActive, ...props }) => (
  <Col xs={5} className="equatorial-measurement-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <g data-name="Grupo 2387">
        <g fill="#fff" stroke="#5d5d5d" data-name="Elipse 2">
          <circle cx={16} cy={16} r={16} stroke="none" />
          <circle
            cx={16}
            cy={16}
            r={15.5}
            fill={isActive ? "#5D5D5D" : "none"}
          />
        </g>
        <path
          fill={isActive ? "white" : "#5D5D5D"}
          d="M24.833 24.657a.543.543 0 0 1-.733.245l-16.164-8.08a.549.549 0 0 1 .2-1.059H20.29a3.505 3.505 0 0 0 0-7.009h-7.663v.691a.349.349 0 0 1-.048.185.373.373 0 0 1-.137.137c-.2.114-2.146 1.241-2.15 1.241a.389.389 0 0 1-.187.05.393.393 0 0 1-.185-.05S7.973 9.881 7.77 9.767a.357.357 0 0 1-.131-.137.35.35 0 0 1-.05-.185V6.964a.386.386 0 0 1 .181-.322c.2-.114 2.15-1.241 2.15-1.241a.393.393 0 0 1 .185-.05.389.389 0 0 1 .187.05l2.15 1.241a.422.422 0 0 1 .137.137.359.359 0 0 1 .048.185v.695h7.663a4.6 4.6 0 0 1 0 9.2h-9.846l14.146 7.07a.536.536 0 0 1 .245.728"
          data-name="Caminho 4468"
        />
      </g>
    </svg>
  </Col>
);
export default ReactivePowerIcon;
