import useAlertFiltersStore from "../stores/useAlertFiltersStore";

export const defaultAlertFilters = {
  read: undefined,
  archived: false,
  types: undefined,
  mitigated: undefined,
  triggers: undefined
};

const getReadValue = status => {
  if (status.includes("read") && status.includes("unread")) return undefined;
  else if (status.includes("read")) return true;
  else if (status.includes("unread")) return false;

  return undefined;
};

const getArchivedValue = status => {
  if (status.includes("archived") && status.includes("not-archived"))
    return undefined;
  else if (status.includes("archived")) return true;
  else if (status.includes("not-archived")) return false;

  return undefined;
};

const getMitigatedValue = mitigated => {
  if (mitigated.includes("mitigated") && mitigated.includes("not-mitigated"))
    return undefined;
  else if (mitigated.includes("mitigated")) return true;
  else if (mitigated.includes("not-mitigated")) return false;

  return undefined;
};

const getTriggerValue = trigger => {
  const mappedTriggers = trigger.map(({ field }) => field);
  const triggerHasAll =
    mappedTriggers.includes("all") || trigger.includes("all");

  if (triggerHasAll || mappedTriggers.length < 1) return undefined;
  else return trigger;
};

const getTypesValue = type => {
  if (type.length < 1) return undefined;
  else return type;
};

const useGetMappedFilters = () => {
  const { type, status, mitigated, trigger } = useAlertFiltersStore(
    state => state.filters
  );

  const mappedFilters = { ...defaultAlertFilters };

  mappedFilters.read = getReadValue(status);
  mappedFilters.archived = getArchivedValue(status);
  mappedFilters.mitigated = getMitigatedValue(mitigated);
  mappedFilters.triggers = getTriggerValue(trigger);
  mappedFilters.types = getTypesValue(type);

  return mappedFilters;
};

export default useGetMappedFilters;
