import React from "react";
import "./EquatorialHexagon.scss";
import { Col } from "react-bootstrap";
import useSelectedMeasurementStore from "../stores/useSelectedMeasurementStore";
import useFormatEquatorialValues from "../hooks/useFormatEquatorialValues";

const LABEL_TITLE = {
  total: "Total",
  neutral: "Neutro",
  "phase-a": "Fase A",
  "phase-b": "Fase B",
  "phase-c": "Fase C"
};

const EquatorialHexagon = ({ consumption, field }) => {
  const selectedMeasurement = useSelectedMeasurementStore(
    state => state.selectedMeasurement
  );

  const unit = selectedMeasurement.unit || "";

  const { formatValue } = useFormatEquatorialValues();

  return (
    <Col className="consumption-widget-container" lg={3} xs={6}>
      <div className={"eq-hex " + field}>
        <div className="hex-value">
          <div className="hex-demand">{formatValue(consumption)}</div>
          <div className="hex-unity">{unit}</div>
        </div>
      </div>
      <div className="eq-label">
        <p className={"title " + field}>{LABEL_TITLE[field]}</p>
      </div>
    </Col>
  );
};

export default EquatorialHexagon;
