import useConsumptionOrMagnituteFilterStore, {
  CONSUMPTION_OR_MAGNITUDE_FILTERS
} from "../stores/useConsumptionOrMagnituteFilterStore";

const useIsConsumptionFilterOn = () => {
  const {
    activeConsumptionOrMagnituteFilter
  } = useConsumptionOrMagnituteFilterStore();

  return (
    activeConsumptionOrMagnituteFilter ===
    CONSUMPTION_OR_MAGNITUDE_FILTERS.CONSUMPTION
  );
};

export default useIsConsumptionFilterOn;
