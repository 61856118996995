import { create } from "zustand";
import { isEQUATORIALEnv } from "../utils/EnvUtils";

export const CONSUMPTION_OR_MAGNITUDE_FILTERS = {
  CONSUMPTION: "consumption",
  MAGNITUDE: "magnitude"
};

const useConsumptionOrMagnituteFilterStore = create(set => ({
  activeConsumptionOrMagnituteFilter: isEQUATORIALEnv
    ? CONSUMPTION_OR_MAGNITUDE_FILTERS.MAGNITUDE
    : CONSUMPTION_OR_MAGNITUDE_FILTERS.CONSUMPTION,
  setMagnituteFilterOn: () =>
    set({
      activeConsumptionOrMagnituteFilter:
        CONSUMPTION_OR_MAGNITUDE_FILTERS.MAGNITUDE
    }),
  setConsumptionFilterOn: () =>
    set({
      activeConsumptionOrMagnituteFilter:
        CONSUMPTION_OR_MAGNITUDE_FILTERS.CONSUMPTION
    })
}));

export default useConsumptionOrMagnituteFilterStore;
