import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Chart from "./Chart";
import "./Chart.scss";

const PeopleChart = ({ timeType, data }) => {
  const getUnit = () => {
    return "Pessoas";
  };

  const getDateFormat = useCallback(() => {
    return timeType === "today" ? "%d/%m/%Y, %H:%M" : "%d/%m/%Y";
  });

  const format = value => {
    return value + " " + getUnit();
  };

  let [options, setOptions] = useState({
    chart: {
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 0,
      style: {
        fontFamily: "Ubuntu"
      }
    },

    time: {
      useUTC: false
    },

    title: {
      text: ""
    },

    credits: false,

    xAxis: {
      type: "datetime",
      minorTickLength: 0,
      tickLength: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",
      title: {
        text: "Horário"
      }
    },

    yAxis: {
      title: {
        text: "CONTAGEM (" + getUnit() + ")"
      },

      labels: {
        formatter: function() {
          return format(this.value);
        }
      },

      plotLines: [
        {
          value: 0,
          width: 1,
          color: "#808080"
        }
      ],

      minorTickLength: 0,
      tickLength: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",

      endOnTick: false
    },

    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 1
      },
      series: {
        pointWidth: 10
      }
    },

    tooltip: {
      enabled: true,
      xDateFormat: getDateFormat(),
      pointFormatter: function() {
        var out = "";
        out += "<b>" + this.y.toFixed + " " + getUnit();
        return out;
      }
    },

    legend: {
      enabled: false
    },

    exporting: {
      enabled: false
    },

    series: [
      {
        id: "consumption",
        name: "conusmption",
        color: "#F35456",
        data: data,
        type: "column"
      }
    ]
  });

  useEffect(() => {
    setOptions({
      ...options,
      tooltip: {
        enabled: true,
        xDateFormat: getDateFormat(),
        pointFormatter: function() {
          var out = "";
          out += "<b>" + Math.trunc(this.y) + " " + getUnit();
          return out;
        }
      },
      series: [
        {
          id: "consumption",
          name: "conusmption",
          color: "#F35456",
          data: data,
          type: "column"
        }
      ]
    });
  }, [data]);

  return (
    <div className="consumption-chart-container">
      <Row className="title-container">
        <Col className="chart-title">
          <div>Histórico de Contagem</div>
          <div>{timeType === "today" ? "últimas 24 horas" : "no mês"}</div>
        </Col>
      </Row>
      <Row className="chart-container">
        <Col className="chart-column">
          {data.length > 0 && <Chart options={options}></Chart>}
          {data.length === 0 && (
            <div className="chart-error-text">
              Não há dados de contagem para o período
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

PeopleChart.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array,
  timeType: PropTypes.string
};

export default PeopleChart;
