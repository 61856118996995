import useIsUserSignedIn from "../hooks/useIsUserSignedIn";

const ProtectedLoginRoute = ({ children }) => {
  const isUserSignedIn = useIsUserSignedIn();

  const redirectToHome = () => {
    window.location = "/";
  };

  if (isUserSignedIn) redirectToHome();
  else return children;
};

export default ProtectedLoginRoute;
