import React, { useState } from "react";
import PropTypes from "prop-types";
import BuildingsModal from "../BuildingsModal/BuildingsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import "./UserRow.scss";

const UserRow = ({ user, buildings }) => {
  const [modalShow, setModalShow] = useState(false);
  const [authNumber, setAuthNumber] = useState(
    user.authorizedBuildings ? user.authorizedBuildings.length : 0
  );

  return (
    <div>
      <BuildingsModal
        user={user}
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
        buildings={buildings}
        authNumber={authNumber}
        setAuthNumber={setAuthNumber}
      />
      <section className={"user-header"}>
        <div className={"text"}>
          <section className="user-description">
            {user.firstName} {user.lastName}
          </section>
          <section className="user-identifier">{user.email}</section>
        </div>
        <div className={"num-text"}>
          <section className="user-num">
            {authNumber}/{buildings.length}
          </section>
        </div>
        <div
          className={"button access-management"}
          onClick={() => setModalShow(!modalShow)}
        >
          <FontAwesomeIcon icon={faUserCog} />
        </div>
      </section>
    </div>
  );
};

UserRow.propTypes = {
  user: PropTypes.object
};

export default UserRow;
