import React from "react";
import { Row, Col } from "react-bootstrap";
import ConsumptionChart from "../../../components/Chart/ConsumptionChart";
//import DateWidget from "../../../components/DateWidget/DateWidget";
import DemandWidget from "../../../components/DemandWidget/DemandWidget";
import CompareConsumptionWidget from "../../../components/CompareConsumptionWidget/CompareConsumptionWidget";
import ConsumptionWidget from "../../../components/ConsumptionWidget/ConsumptionWidget";
import AreaList from "../../../components/AreaList/AreaList";
//import AlertListWidget from "../../../components/AlertListWidget/AlertListWidget";
import AreaHeader from "../../../components/AreaHeader/AreaHeader";
import TimeTypeSelector from "../../../components/TimeTypeSelector/TimeTypeSelector";
import moment from "moment";
import "../../Dashboard.scss";

const validateTime = data => (data ? data.end : new Date().getTime());

const Water = ({
  area,
  date,
  //admin,
  showAll,
  setDate,
  timeType,
  chartData,
  waterData,
  //alertsData,
  demandData,
  compareData,
  onItemClick,
  //onAlertClick,
  allAreasData,
  onShowAllClick,
  setTimeType
  //isReadOnlyUser
}) => {
  const getTime = () => {
    if (timeType === "today") {
      return "00h - " + moment().hour() + "h" + moment().minute();
    } else {
      return moment(date).format("MMMM");
    }
  };
  return (
    <>
      <Row className="header">
        <Col xs={12} lg={4} className="header-column">
          <AreaHeader
            areaName={area.name}
            areaUpperName={process.env.REACT_APP_CAMPUS}
            onUpperNameClick={onShowAllClick}
            showAll={showAll}
            typeMeter="medição"
            tab="water"
            date={date}
            setDate={setDate}
            showBackButton={allAreasData?.areas?.length > 1}
          />
        </Col>
        <Col xs={12} lg={4} className="timetype-column">
          <TimeTypeSelector
            timeType={timeType}
            onTimeTypeChange={setTimeType}
          />
        </Col>
        <Col xs={1} lg={1}></Col>
      </Row>
      <Row className="components-title">
        <Col xs={12}>Visão Geral</Col>
      </Row>
      <Row className="components">
        {/* <Col xs={12} lg={3}>
          <DateWidget
            type="water"
            showAll={showAll}
            timeType={timeType}
            date={date}
            setDate={setDate}
          ></DateWidget>
        </Col> */}
        {!showAll && (
          <Col sm={12} lg={4}>
            <DemandWidget
              demand={waterData.demand}
              time={validateTime(demandData)}
              type="water"
            ></DemandWidget>
          </Col>
        )}
        {showAll && (
          <Col sm={12} lg={3}>
            <DemandWidget
              demand={waterData.demand}
              time={validateTime(demandData)}
              type="water"
            ></DemandWidget>
          </Col>
        )}
        {!showAll && (
          <Col sm={12} lg={4} className="compare-widget">
            <CompareConsumptionWidget
              type="water"
              timeType={timeType}
              daily={waterData.comparation[timeType].daily}
              average={waterData.comparation[timeType].average}
              lastMeasurement={chartData && chartData[chartData.length - 1]}
              hour={compareData.hour}
            ></CompareConsumptionWidget>
          </Col>
        )}
        {!showAll && (
          <Col sm={12} lg={4}>
            <ConsumptionWidget
              consumption={waterData.consumption[timeType]}
              timeType={timeType}
              type="water"
              date={date}
            ></ConsumptionWidget>
          </Col>
        )}
        {showAll && (
          <Col sm={12} lg={3}>
            <ConsumptionWidget
              consumption={waterData.consumption[timeType]}
              timeType={timeType}
              type="water"
              date={date}
            ></ConsumptionWidget>
          </Col>
        )}
        {showAll && (
          <Col sm={12} lg={6} className="area-col">
            <AreaList
              type="water"
              title="Consumo por pontos de medição"
              subtitle={getTime()}
              // subtitle="(m³)"
              onItemClick={onItemClick}
              fontType="water"
              timeType={timeType}
            >
              {allAreasData.areas &&
                allAreasData.areas.map((area, index) => (
                  <Row
                    key={index}
                    buildingid={area.buildingId}
                    areaname={area.name}
                  >
                    <Col xs={6}>{area.name}</Col>
                    <Col xs={6}>{area.consumption?.toFixed(2)}</Col>
                  </Row>
                ))}
            </AreaList>
          </Col>
        )}
      </Row>
      <Row className="dashboard-chart">
        <Col>
          <ConsumptionChart
            type="water"
            data={showAll ? allAreasData.chart : chartData}
            timeType={timeType}
            date={date}
          ></ConsumptionChart>
        </Col>
      </Row>
      {/* {admin && (
        <Row className="alert-widget-row">
          <Col>
            <AlertListWidget
              alerts={showAll ? allAreasData.alerts : alertsData}
              type="water"
              onAlertClick={onAlertClick}
              isReadOnlyUser={isReadOnlyUser}
            />
          </Col>
        </Row>
      )} */}
    </>
  );
};

export default Water;
