import React from "react";
import PropTypes from "prop-types";
import "./Alert.scss";

const Alert = ({ title, text, type, buildingId, onAlertClick }) => {
  return (
    <div
      className={"alert " + type}
      onClick={() => {
        onAlertClick(buildingId);
      }}
    >
      <p className="title">{title}</p>
      <span className="content">{text}</span>
    </div>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  buildingId: PropTypes.string,
  onAlertClick: PropTypes.func
};

export default Alert;
