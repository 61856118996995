import React from "react";
import PropTypes from "prop-types";
import "./GradientRule.scss";

const GradientRule = ({ points }) => {
  return (
    <div className="gradient-rule-container">
      <div className="gradient-rule-steps">
        {points.map((point, index) => (
          <div className="step" key={index}>
            <div className="step-label">{point.value}</div>
            <div
              className="step-color"
              style={{ backgroundColor: point.color }}
            ></div>
          </div>
        ))}
        {/*<div className="gradient-rule-footer">0 kWh</div>*/}
      </div>
      <div className="gradient-rule-title">Consumo</div>
    </div>
  );
};

GradientRule.propTypes = {
  points: PropTypes.array.isRequired
};

export default GradientRule;
