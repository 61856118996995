import React from "react";
import { Row, Col } from "react-bootstrap";
import PeopleChart from "../../../components/Chart/PeopleChart";
import DateWidget from "../../../components/DateWidget/DateWidget";
import DemandWidget from "../../../components/DemandWidget/DemandWidget";
import CompareConsumptionWidget from "../../../components/CompareConsumptionWidget/CompareConsumptionWidget";
import ConsumptionWidget from "../../../components/ConsumptionWidget/ConsumptionWidget";
import AlertListWidget from "../../../components/AlertListWidget/AlertListWidget";
import AreaHeader from "../../../components/AreaHeader/AreaHeader";
import TimeTypeSelector from "../../../components/TimeTypeSelector/TimeTypeSelector";

import "../../Dashboard.scss";

const MobSense = ({
  area,
  date,
  admin,
  showAll,
  setDate,
  timeType,
  chartData,
  mobsenseData,
  alertsData,
  demandData,
  compareData,
  onAlertClick,
  allAreasData,
  onShowAllClick,
  setTimeType,
  isReadOnlyUser
}) => {
  return (
    <>
      <Row className="header">
        <Col xs={12} md={4} className="header-column">
          <AreaHeader
            areaName={area.name}
            areaUpperName={process.env.REACT_APP_CAMPUS}
            onUpperNameClick={onShowAllClick}
            showAll={showAll}
            showBackButton={allAreasData?.areas?.length > 1}
          />
        </Col>
        <Col xs={12} lg={4} className="timetype-column">
          <TimeTypeSelector
            timeType={timeType}
            onTimeTypeChange={setTimeType}
          />
        </Col>
        <Col xs={12} lg={4}></Col>
      </Row>
      <Row className="components-title">
        <Col xs={12}>Visão Geral</Col>
      </Row>
      <Row className="components">
        <Col xs={12} md={4}>
          <DateWidget
            type="water"
            showAll={showAll}
            timeType={timeType}
            date={date}
            setDate={setDate}
          ></DateWidget>
        </Col>
        {!showAll && (
          <Col sm={12} md={3}>
            <DemandWidget
              demand={mobsenseData.currentCount}
              time={demandData.end}
              type="mobsense"
            ></DemandWidget>
          </Col>
        )}
        {showAll && (
          <Col sm={12} md={4}>
            <DemandWidget
              demand={mobsenseData.currentCount}
              time={demandData.end}
              type="mobsense"
            ></DemandWidget>
          </Col>
        )}
        {!showAll && (
          <Col sm={12} md={3} className="compare-widget">
            <CompareConsumptionWidget
              type="mobsense"
              timeType={timeType}
              daily={mobsenseData.comparation[timeType].daily}
              average={mobsenseData.comparation[timeType].average}
              lastMeasurement={chartData[chartData.length - 1]}
              hour={compareData.hour}
            ></CompareConsumptionWidget>
          </Col>
        )}
        {!showAll && (
          <Col sm={12} md={3}>
            <ConsumptionWidget
              consumption={mobsenseData.count[timeType]}
              timeType={timeType}
              type="mobsense"
              date={date}
            ></ConsumptionWidget>
          </Col>
        )}
        {showAll && (
          <Col sm={12} md={4}>
            <ConsumptionWidget
              consumption={mobsenseData.count[timeType]}
              timeType={timeType}
              type="mobsense"
              date={date}
            ></ConsumptionWidget>
          </Col>
        )}
        {/* {showAll && (
          <Col sm={12} md={3} className="area-col">
            <AreaList
              type="mobsense"
              title="Consumo por pontos de medição"
              subtitle="(m³)"
              onItemClick={onItemClick}
            >
              {allAreasData.areas.map((area, index) => (
                <Row
                  key={index}
                  buildingid={area.buildingId}
                  areaname={area.name}
                >
                  <Col xs={8}>{area.name}</Col>
                  <Col xs={4}>{area.consumption.toFixed(2)}</Col>
                </Row>
              ))}
            </AreaList>
          </Col>
        )} */}
      </Row>
      <Row className="dashboard-chart">
        <Col>
          <PeopleChart
            type="mobsense"
            data={showAll ? allAreasData.chart : chartData}
            timeType={timeType}
          ></PeopleChart>
        </Col>
      </Row>
      {admin && (
        <Row className="alert-widget-row">
          <Col>
            <AlertListWidget
              alerts={showAll ? allAreasData.alerts : alertsData}
              type="mobsense"
              onAlertClick={onAlertClick}
              isReadOnlyUser={isReadOnlyUser}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default MobSense;
