import * as React from "react";
import { Col } from "react-bootstrap";

const PowerFactorIcon = ({ isActive, ...props }) => (
  <Col xs={5} className="equatorial-measurement-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <g data-name="Grupo 2387">
        <g fill="#fff" stroke="#5d5d5d" data-name="Elipse 2">
          <circle cx={16} cy={16} r={16} stroke="none" />
          <circle
            cx={16}
            cy={16}
            r={15.5}
            fill={isActive ? "#5D5D5D" : "none"}
          />
        </g>
        <path
          fill={isActive ? "white" : "#5D5D5D"}
          d="M20.653 9.907c-.06.224-.635 2.395-.635 2.4a.338.338 0 0 1-.093.164.347.347 0 0 1-.17.1l-2.4.646a.323.323 0 0 1-.191 0 .33.33 0 0 1-.164-.1l-1.436-1.426-2.908 11.695a.546.546 0 1 1-1.059-.262L15.636 6.92a.546.546 0 0 1 1.061.257l-.266 1.073 2.011-.542a.4.4 0 0 1 .191 0 .368.368 0 0 1 .17.093l1.756 1.75a.393.393 0 0 1 .1.164.408.408 0 0 1 0 .193"
          data-name="Caminho 4472"
        />
      </g>
    </svg>
  </Col>
);
export default PowerFactorIcon;
