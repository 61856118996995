import {
  isLITECAMPUSEnv,
  isPMCGEnv,
  isEQUATORIALEnv
} from "../../utils/EnvUtils";

let Tabs = {
  settings: { title: "CONFIGURAÇÕES", color: "#707070" },
  objectDetector: { title: "DETECTOR DE OBJETOS", color: "#7bb92a" },
  water: { title: "ÁGUA", color: "#007c9f" },
  actuators: { title: "ATUADORES", color: "#00796b" },
  alerts: { title: "ALERTAS", color: "#303030" },
  energy: { title: "ENERGIA", color: "#f8be22" }
};

delete Tabs.objectDetector;

if (isLITECAMPUSEnv) delete Tabs.settings;
if (isPMCGEnv) delete Tabs.water && delete Tabs.objectDetector;
if (isEQUATORIALEnv)
  delete Tabs.actuators && delete Tabs.water && delete Tabs.objectDetector;

export default Tabs;
