import { Service, ActuatorsService } from "./Service";
import { getDescriptorFromRule } from "../utils/RulesUtils";

export const getRules = async actuators => {
  let hardwareIds = actuators.map(actuator => actuator.hardware_id).join(",");
  try {
    let rules = await Service.get("/withoutauth/multisensor/alert", {
      params: { hardwareIds: hardwareIds }
    })
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return [];
      });
    return rules;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const registerRule = async ruleDescriptor => {
  try {
    await Service.post("/withoutauth/multisensor/alert", ruleDescriptor, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    await ActuatorsService.post("cmnd/register-rule", {
      authentication: localStorage.getItem("AuthData")
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateRule = async (ruleId, ruleDescriptor) => {
  try {
    await Service.put(
      "/withoutauth/multisensor/alert/" + ruleId,
      ruleDescriptor,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const removeRule = async ruleId => {
  try {
    await Service.delete("/withoutauth/multisensor/alert/" + ruleId);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const setRuleEnabled = async (rule, enabled) => {
  const ruleDescriptor = getDescriptorFromRule(rule);
  ruleDescriptor.enabled = enabled;

  try {
    await Service.put(
      "/withoutauth/multisensor/alert/" + rule.id,
      ruleDescriptor,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
