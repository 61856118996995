import React, { useState } from "react";
import { FAQData, FAQNavConfig } from "../../data/faqData";
import InnerNavbar from "../../components/InnerNavbar/InnerNavbar";
import FAQList from "../../components/FAQList/FAQList";

const FAQ = () => {
  const [currentSection, setCurrentSection] = useState(FAQNavConfig[0].value);

  return (
    <div>
      <InnerNavbar
        activeSection={currentSection}
        changeSection={setCurrentSection}
        sections={FAQNavConfig}
      ></InnerNavbar>
      <div className="page-container faq" id="dashboard-page-container">
        <div className="faq-list-container">
          <FAQList
            data={FAQData[currentSection]}
            faqListStyle={currentSection}
          />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
