import React, { useState, useEffect } from "react";
import Label from "./Label";
import PropTypes from "prop-types";

import "./Bars.scss";
import HexBar from "./HexBar";

const Bars = ({ daily, average, type }) => {
  let [firstBar, setFirstBar] = useState(0);
  let [secondBar, setSecondBar] = useState(0);

  useEffect(() => {
    const absDaily = Math.abs(daily);
    const absAverage = Math.abs(average);

    if (absDaily > absAverage) {
      const secondBarNewValue = (120 * absAverage) / absDaily;

      setFirstBar(120);
      setSecondBar(secondBarNewValue);
    } else if (absDaily === 0 && absAverage === 0) {
      setFirstBar(0);
      setSecondBar(0);
    } else {
      const firstBarNewValue = (120 * absDaily) / absAverage;

      setFirstBar(firstBarNewValue);
      setSecondBar(120);
    }
  }, [daily, average]);

  return (
    <div className="chart">
      <Label className="right" title="Atual" value={daily} type={type} />
      <div className="bars">
        <HexBar height={firstBar} type={type} />
        <HexBar height={secondBar} type={type} />
      </div>
      <Label title="Média" value={average} type={type} />
    </div>
  );
};

Bars.propTypes = {
  daily: PropTypes.number,
  average: PropTypes.number,
  type: PropTypes.string
};

export default Bars;
