import { create } from "zustand";

const useUserCredentialsStore = create(set => ({
  loggedUser: undefined,
  isSuperUser: false,
  isReadOnlyUser: true,
  setLoggedUser: loggedUser => set({ loggedUser }),
  setIsSuperUser: isSuperUser => set({ isSuperUser }),
  setIsReadOnlyUser: isReadOnlyUser => set({ isReadOnlyUser }),
  getIsSignedIn: () => JSON.parse(localStorage.getItem("isSignedIn"))
}));

export default useUserCredentialsStore;
