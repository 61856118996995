import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "../../../components/AreaHeader/AreaHeader.scss";
import useSelectedAreaStore from "../../../components/Dashboard/stores/useSelectedAreaStore";

const EquatorialAreaHeader = () => {
  const selectedArea = useSelectedAreaStore(state => state.selectedArea);
  const resetSelectedArea = useSelectedAreaStore(
    state => state.resetSelectedArea
  );
  const hasSelectedArea = useSelectedAreaStore(state => state.hasSelectedArea);

  const showBackButton = !!hasSelectedArea;

  return (
    <div className="area-header-container">
      <section style={{ display: "flex" }}>
        <div className="area-upper-name" onClick={resetSelectedArea}>
          {process.env.REACT_APP_CAMPUS}
        </div>
      </section>
      <section style={{ marginTop: "5px" }}>
        <div className="area-name">
          {showBackButton && (
            <FontAwesomeIcon
              className="back-icon"
              onClick={resetSelectedArea}
              icon={faChevronLeft}
              title="Voltar"
              size="1x"
            />
          )}
          {selectedArea?.name || "Transformadores"}
        </div>
      </section>
    </div>
  );
};

EquatorialAreaHeader.propTypes = {
  areaName: PropTypes.string,
  areaUpperName: PropTypes.string,
  onUpperNameClick: PropTypes.func
};

export default EquatorialAreaHeader;
