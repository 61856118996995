import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamation,
  faTimes,
  faPowerOff
} from "@fortawesome/free-solid-svg-icons";

const aToZ = {
  accessor: "a-to-z",
  label: "A-Z",
  title: "Ordenar pontos alfabéticamente",
  width: 1,
  component: <div className="detailed-filter-text">A-Z</div>,
  sortFunction: (a, b) => (a?.name > b?.name ? 1 : a?.name === b?.name ? 0 : -1)
};

const highestValue = {
  accessor: "highest-value",
  label: "Maior",
  title: "Ordenar pelo maior valor",
  width: 1,
  component: (
    <>
      <div className="detailed-filter-text bigger-screen">Maior</div>
      <div className="detailed-filter-text smaller-screen">&gt;</div>
    </>
  ),
  sortFunction: (a, b) =>
    a?.value + b?.value !== 0
      ? a?.value < b?.value
        ? 1
        : a?.value === b?.value
        ? 0
        : -1
      : a?.phases?.a + a?.phases?.b + a?.phases?.c <
        b?.phases?.a + b?.phases?.b + b?.phases?.c
      ? 1
      : a?.phases?.a + a?.phases?.b + a?.phases?.c ===
        b?.phases?.a + b?.phases?.b + b?.phases?.c
      ? 0
      : -1
};

const lowestValue = {
  accessor: "lowest-value",
  label: "Menor",
  title: "Ordenar pelo menor valor",
  width: 1,
  component: (
    <>
      <div className="detailed-filter-text bigger-screen">Menor</div>
      <div className="detailed-filter-text smaller-screen">&lt;</div>
    </>
  ),
  sortFunction: (a, b) =>
    a?.value + b?.value !== 0
      ? a?.value > b?.value
        ? 1
        : a?.value === b?.value
        ? 0
        : -1
      : a?.phases?.a + a?.phases?.b + a?.phases?.c >
        b?.phases?.a + b?.phases?.b + b?.phases?.c
      ? 1
      : a?.phases?.a + a?.phases?.b + a?.phases?.c ===
        b?.phases?.a + b?.phases?.b + b?.phases?.c
      ? 0
      : -1
};

const normalStatus = {
  accessor: "normal",
  label: "Normal",
  title: "Operando Normalmente",
  description: "Operando Normalmente",
  width: 1,
  component: (
    <div className="status-indicator-wrapper status-indicator-wrapper-filter">
      <FontAwesomeIcon
        className="status-indicator status-indicator-filter"
        icon={faCheck}
        size="xs"
      />
    </div>
  ),
  filterFunction: a => a?.online && a?.status === "normal"
};

const hasAlerts = {
  accessor: "has-alerts",
  label: "Possui Alertas",
  title: "Possui Alertas",
  description: "Possui Alertas",
  width: 1,
  component: (
    <div className="status-indicator-wrapper status-indicator-wrapper-filter">
      <FontAwesomeIcon
        className="status-indicator status-indicator-filter"
        icon={faExclamation}
        size="xs"
      />
    </div>
  ),
  filterFunction: a => a?.online && a?.status === "has-alerts"
};

const outOfService = {
  accessor: "out-of-service",
  label: "Fora de Serviço",
  title: "Fora de Serviço (Falta de Energia)",
  description: "Fora de Serviço (Falta de Energia)",
  width: 1,
  component: (
    <div className="status-indicator-wrapper status-indicator-wrapper-filter">
      <FontAwesomeIcon
        className="status-indicator status-indicator-filter"
        icon={faTimes}
        size="xs"
      />
    </div>
  ),
  filterFunction: a => a?.online && a?.status === "out-of-service"
};

const offlineStatus = {
  accessor: "offline",
  label: "Offline",
  title: "Offline (Sem enviar dados há mais de 12 horas)",
  description: "Offline (Sem enviar dados há mais de 12 horas)",
  width: 1,
  component: (
    <div className="status-indicator-wrapper status-indicator-wrapper-filter">
      <FontAwesomeIcon
        className="status-indicator status-indicator-filter"
        icon={faPowerOff}
        size="xs"
      />
    </div>
  ),
  filterFunction: a => a?.online === false
};

const EquatorialEnergyDetailedFilters = [
  aToZ,
  highestValue,
  lowestValue,
  normalStatus,
  hasAlerts,
  outOfService,
  offlineStatus
];

const EquatorialEnergyDetailedFiltersMapping = {
  "a-to-z": aToZ,
  "highest-value": highestValue,
  "lowest-value": lowestValue,
  "normal-status": normalStatus,
  "has-alerts": hasAlerts,
  "out-of-service": outOfService,
  "offline-status": offlineStatus
};

export {
  EquatorialEnergyDetailedFilters,
  EquatorialEnergyDetailedFiltersMapping
};
