import React from "react";
import { Col, Row } from "react-bootstrap";
import ActivePowerIcon from "./icons/ActivePowerIcon";
import IRMSIcon from "./icons/IRMSIcon";
import PowerFactorIcon from "./icons/PowerFactorIcon";
import ReactivePowerIcon from "./icons/ReactivePowerIcon";
import VRMSIcon from "./icons/VRMSIcon";

const activePower = {
  accessor: "active_power",
  label: "PA",
  title: "Potência Ativa",
  unit: "kW",
  width: 1,
  component: isActive => (
    <Row className="equatorial-measurement-filter-wrapper">
      <ActivePowerIcon isActive={isActive} />
      <Col
        xs={7}
        className={`equatorial-measurement-label ${isActive ? "active" : ""}`}
      >
        Potência <br /> Ativa
      </Col>
    </Row>
  )
};

const irms = {
  accessor: "irms",
  label: "C",
  title: "Corrente",
  unit: "A",
  width: 1,
  component: isActive => (
    <Row className="equatorial-measurement-filter-wrapper">
      <IRMSIcon isActive={isActive} />
      <Col
        xs={7}
        className={`equatorial-measurement-label ${isActive ? "active" : ""}`}
      >
        Corrente
      </Col>
    </Row>
  )
};

const powerFactor = {
  accessor: "power_factor",
  label: "FP",
  title: "Fator de Potência",
  unit: "",
  width: 1,
  component: isActive => (
    <Row className="equatorial-measurement-filter-wrapper">
      <PowerFactorIcon isActive={isActive} />
      <Col
        xs={7}
        className={`equatorial-measurement-label ${isActive ? "active" : ""}`}
      >
        Fator de <br /> Potência
      </Col>
    </Row>
  )
};

const reactivePower = {
  accessor: "reactive_power",
  label: "PR",
  title: "Potência Reativa",
  unit: "kVAr",
  width: 1,
  component: isActive => (
    <Row className="equatorial-measurement-filter-wrapper">
      <ReactivePowerIcon isActive={isActive} />
      <Col
        xs={7}
        className={`equatorial-measurement-label ${isActive ? "active" : ""}`}
      >
        Potência <br /> Reativa
      </Col>
    </Row>
  )
};

const vrms = {
  accessor: "vrms",
  label: "T",
  title: "Tensão",
  unit: "V",
  width: 1,
  component: isActive => (
    <Row className="equatorial-measurement-filter-wrapper">
      <VRMSIcon isActive={isActive} />
      <Col
        xs={7}
        className={`equatorial-measurement-label ${isActive ? "active" : ""}`}
      >
        Tensão
      </Col>
    </Row>
  )
};

const EquatorialEnergyDetailedMeasurementFilters = [
  activePower,
  reactivePower,
  vrms,
  irms,
  powerFactor
];

const EquatorialEnergyDetailedMeasurementFiltersMapping = {
  active_power: activePower,
  irms: irms,
  power_factor: powerFactor,
  reactive_power: reactivePower,
  vrms: vrms
};

export {
  EquatorialEnergyDetailedMeasurementFilters,
  EquatorialEnergyDetailedMeasurementFiltersMapping
};
