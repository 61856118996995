import React from "react";
import PropTypes from "prop-types";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const defaultOptions = {
  title: {
    text: "My chart"
  },
  series: [
    {
      data: [1, 2, 3]
    }
  ]
};

const Chart = ({ options }) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options ? options : defaultOptions}
    />
  );
};

Chart.propTypes = {
  options: PropTypes.object
};

export default Chart;
