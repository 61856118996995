import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, ListGroup, ButtonToolbar, Button } from "react-bootstrap";
import PropTypes from "prop-types";

import {
  addAccessPoint,
  fetchAccessPoints,
  removeAccessPoint
} from "../../../actions/wifi";

import "./Wifi.scss";
import WifiModal from "./WifiModal";
import EditWifiModal from "./EditWifiModal";
import swal from "@sweetalert/with-react";

const mapStateToProps = state => {
  return {
    admin: state.auth.isAdmin,
    accessPoints: state.wifi.accessPoints,
    buildings: state.wifi.buildings
  };
};

const Wifi = ({
  admin,
  buildings,
  accessPoints,
  setSelectedArea,
  selectedArea,
  fetchAccessPoints,
  showAll,
  removeAccessPoint,
  setShowAll
}) => {
  const [wifiNumber, setWifiNumber] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editAP, setEditAP] = useState({});

  useEffect(() => {
    setWifiNumber(accessPoints.length);
  }, [accessPoints, selectedArea]);

  useEffect(() => {
    if (showAll) setWifiNumber(accessPoints.length);
    else {
      let building = buildings.find(e => {
        return e.name === selectedArea.name;
      });
      building ? setWifiNumber(building.points) : setWifiNumber(0);
    }
  }, [accessPoints.length, buildings, selectedArea, showAll]);

  const filterByBuilding = accessPoint =>
    selectedArea.name ? accessPoint.local === selectedArea.name : true;

  const removePoint = point => {
    removeAccessPoint(point)
      .then(() => {
        swal(
          "Ponto de Acesso removido",
          "O Ponto foi removido com sucesso.",
          "success"
        ).then(() => {
          fetchAccessPoints();
          setModalShow(false);
        });
      })
      .catch(() => {
        swal(
          "Ops... Algo deu errado",
          "Não foi possível remover a rede.",
          "error"
        );
      });
  };

  return (
    <>
      <Row className="wifi-tab">
        <Col lg={4}>
          <div className="buildings-list-title">
            <h5>Visão Geral</h5>
            <div className="buildings-list-header">
              <span>Nome</span>
              <span>Pontos</span>
            </div>
          </div>
          <ListGroup className="buildings-list">
            {buildings.map(building => (
              <ListGroup.Item
                onClick={() => {
                  setSelectedArea({
                    name: building.name
                  });
                  setShowAll(false);
                }}
                key={building.name}
                className={
                  "buildings-list-item " +
                  (selectedArea.name === building.name && "active")
                }
              >
                <span> {building.name}</span>
                <span> {building.points} </span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col lg={8}>
          {!showAll && admin && (
            <ButtonToolbar>
              <Button
                id="add-wifi"
                className="button"
                variant="primary"
                onClick={() => setModalShow(true)}
              >
                Adicionar Rede WiFi
              </Button>

              <WifiModal
                area={selectedArea.name}
                show={modalShow}
                setModalShow={setModalShow}
                onHide={() => setModalShow(false)}
              />
            </ButtonToolbar>
          )}
          <div className="wifi-header">
            <p>Sinais WiFi: {wifiNumber}</p>
          </div>
          <div className={"wifi-list-header " + admin}>
            <span>Nome</span>
            <span>Senha</span>
          </div>
          <ListGroup className="wifi-list">
            {accessPoints.filter(filterByBuilding).map((point, index) => (
              <ListGroup.Item className="wifi-list-item" key={index}>
                <div className="content">
                  <span>{point.name}</span>
                  {point.password !== "NULL" && point.password ? (
                    <span> {point.password} </span>
                  ) : (
                    <span className="wifi-unavailable">não informado</span>
                  )}
                </div>
                {admin && (
                  <>
                    <button
                      id="edit"
                      onClick={() => {
                        setEditAP(point);
                        setEditModalShow(true);
                      }}
                      className="edit"
                    >
                      <svg
                        style={{ width: "24px", height: "24px" }}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#7e0cf5"
                          d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                        />
                      </svg>
                    </button>
                    <button className="edit" onClick={() => removePoint(point)}>
                      <svg
                        style={{ width: "24px", height: "24px" }}
                        fill="#7e0cf5"
                        height="407pt"
                        viewBox="-48 0 407 407"
                        width="407pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m89.199219 37c0-12.132812 9.46875-21 21.601562-21h88.800781c12.128907 0 21.597657 8.867188 21.597657 21v23h16v-23c0-20.953125-16.644531-37-37.597657-37h-88.800781c-20.953125 0-37.601562 16.046875-37.601562 37v23h16zm0 0" />
                        <path d="m60.601562 407h189.199219c18.242188 0 32.398438-16.046875 32.398438-36v-247h-254v247c0 19.953125 14.15625 36 32.402343 36zm145.597657-244.800781c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm0 0" />
                        <path d="m20 108h270.398438c11.046874 0 20-8.953125 20-20s-8.953126-20-20-20h-270.398438c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20zm0 0" />
                      </svg>
                    </button>
                  </>
                )}
              </ListGroup.Item>
            ))}

            <EditWifiModal
              point={editAP}
              show={editModalShow}
              setModalShow={setEditModalShow}
              onHide={() => setEditModalShow(false)}
            />
          </ListGroup>
        </Col>
      </Row>
    </>
  );
};

Wifi.propTypes = {
  admin: PropTypes.bool,
  buildings: PropTypes.array,
  accessPoints: PropTypes.array,
  setSelectedArea: PropTypes.func,
  selectedArea: PropTypes.object,
  fetchAccessPoints: PropTypes.func,
  showAll: PropTypes.bool,
  removeAccessPoint: PropTypes.func,
  setShowAll: PropTypes.func
};

export default connect(
  mapStateToProps,
  {
    addAccessPoint,
    fetchAccessPoints,
    removeAccessPoint
  }
)(Wifi);
