import React, { useEffect } from "react";
import TimeTypeSelector from "../../../components/TimeTypeSelector/TimeTypeSelector";
import "../../Dashboard.scss";
import "./EquatorialEnergy.scss";
import useSelectedAreaStore from "../../../components/Dashboard/stores/useSelectedAreaStore";
import useTimeTypeStore from "../../../components/Dashboard/stores/useTimeTypeStore";
import EquatorialAreaHeader from "./EquatorialAreaHeader";
import { Button, Col, Container, Row } from "react-bootstrap";
import { EquatorialEnergyDetailedMeasurementFilters } from "./EquatorialEnergyDetailedMeasurementFilters";
import useSelectedMeasurementStore from "./stores/useSelectedMeasurementStore";
import useDateStore from "../../../components/Dashboard/stores/useDateStore";
import moment from "moment";
import EquatorialHexagon from "./EquatorialHexagon/EquatorialHexagon";
import useGetMeasurementUtils from "./hooks/useGetMeasurementUtils";
import EnergyMoreInfo from "../../../assets/img/more-details.svg";
import { Service } from "../../../api/Service";
import EquatorialEnergyChart from "./EquatorialEnergyChart";
import useEquatorialWidgetsDataStore from "./stores/useEquatorialWidgetsDataStore";
import useAllDataForSelectedBuilding from "./hooks/useAllDataForSelectedBuilding";

const MINUTES_TO_RELOAD = 3;

const EquatorialEnergyWidgets = ({ admin }) => {
  const date = useDateStore(state => state.date);
  const selectedArea = useSelectedAreaStore(state => state.selectedArea);

  const setEquatorialWidgetsData = useEquatorialWidgetsDataStore(
    state => state.setEquatorialWidgetsData
  );

  const { buildingId } = selectedArea;

  useEffect(() => {
    async function fetchData() {
      const { data } = await Service.get(
        `measurement/building/${buildingId}?timestamp=${new Date().getTime()}`
      );

      setEquatorialWidgetsData(data);
    }

    fetchData();

    const intervalId = setInterval(fetchData, MINUTES_TO_RELOAD * 1000 * 60);

    return () => clearInterval(intervalId);
  }, [selectedArea]);

  const buildingData = useAllDataForSelectedBuilding();

  const { value, phases, timestamp } = buildingData?.current || {};

  const timeType = useTimeTypeStore(state => state.timeType);
  const setTimeType = useTimeTypeStore(state => state.setTimeType);

  const selectedMeasurement = useSelectedMeasurementStore(
    state => state.selectedMeasurement
  );

  const setSelectedMeasurement = useSelectedMeasurementStore(
    state => state.setSelectedMeasurement
  );

  const { hasNeutralValue, hasTotalValue } = useGetMeasurementUtils();

  const authToken = localStorage.getItem("AuthData")
    ? encodeURIComponent(localStorage.getItem("AuthData"))
    : encodeURIComponent(localStorage.getItem("AuthToken"));

  return (
    <Container className="equatorial-energy-container">
      <Row>
        <EquatorialAreaHeader />
        <Col className="equatorial-energy-time-type-selector" xs="auto">
          <TimeTypeSelector
            timeType={timeType}
            onTimeTypeChange={setTimeType}
          />
        </Col>
        <Col className="equatorial-energy-time-type-date" xs="auto">
          {timeType === "today"
            ? moment(date).format("DD/MM")
            : moment(date).format("MMMM")}
        </Col>
        {buildingId && admin && (
          <Col className="equatorial-energy-link-liteme" lg="auto" xs={4}>
            <Button
              id="link-to-liteme"
              className={"link-to-liteme"}
              title="Para mais informações acesse o LiteMe"
              href={`${process.env.REACT_APP_ENV}/#/login/${buildingId}/home/${authToken}`}
              variant="link"
            >
              <img
                className={"equatorial-more-info-btn"}
                src={EnergyMoreInfo}
                style={{ maxWidth: "7vw" }}
              />
            </Button>
          </Col>
        )}
      </Row>
      <Container className="equatorial-detailed-filters">
        <Row style={{ marginTop: "30px" }}>
          {EquatorialEnergyDetailedMeasurementFilters.map(filter => (
            <Col
              key={filter.accessor}
              title={filter.title}
              className={"equatorial-measurement-wrapper"}
              onClick={() => setSelectedMeasurement(filter)}
            >
              {filter.component(
                selectedMeasurement.accessor === filter.accessor
              )}
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="equatorial-energy-widgets-wrapper">
        <Row className="components-title equatorial-energy-components-title">
          Visão Geral
        </Row>
        <Row className="equatorial-energy-timestamp">
          Última medição - {moment(timestamp).format("HH:mm (DD/MM/YY)")}
        </Row>
        <Row>
          {hasNeutralValue && (
            <EquatorialHexagon consumption={value} field={"neutral"} />
          )}
          {hasTotalValue && (
            <EquatorialHexagon consumption={value} field={"total"} />
          )}
          <EquatorialHexagon consumption={phases?.a} field={"phase-a"} />
          <EquatorialHexagon consumption={phases?.b} field={"phase-b"} />
          <EquatorialHexagon consumption={phases?.c} field={"phase-c"} />
        </Row>
        <Container className="equatorial-energy-graph-wrapper">
          <EquatorialEnergyChart />
        </Container>
      </Container>
    </Container>
  );
};

export default EquatorialEnergyWidgets;
