import { create } from "zustand";
import { EnergyDetailedFilters } from "../Dashboard/Tabs/Energy/EnergyDetailedFilters";
import { isEQUATORIALEnv } from "../utils/EnvUtils";
import { EquatorialEnergyDetailedFilters } from "../Dashboard/Tabs/EquatorialEnergy/EquatorialEnergyDetailedFilters";

const useActiveEnergyFilterStore = create(set => ({
  activeFilter: isEQUATORIALEnv
    ? EquatorialEnergyDetailedFilters[1]
    : EnergyDetailedFilters[1],
  setActiveFilter: activeFilter => set({ activeFilter }),
  setInitialConsumptionFilters: () => {
    set({ activeFilter: EnergyDetailedFilters[1] });
  },
  setInitialMagnitudeFilters: () => {
    set({ activeFilter: EquatorialEnergyDetailedFilters[1] });
  }
}));

export default useActiveEnergyFilterStore;
