import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Row } from "react-bootstrap";
import swal from "@sweetalert/with-react";
import useForm from "react-hook-form";
import { createRNPUser, getRNPUsers } from "../../api/Users";

import "./NewUserModal.scss";

const NewUserModal = ({ show, setModalShow, onHide, setUserList }) => {
  const { handleSubmit, register, errors } = useForm();

  const [agree, setAgree] = useState(false);

  const onSubmit = async values => {
    createRNPUser(values.firstName, values.lastName, values.email)
      .then(() => {
        swal(
          "Usuário criado com sucesso",
          `O usuário com email ${values.email} foi criado com sucesso.`,
          "success"
        ).then(async () => {
          setModalShow(false);
          await getRNPUsers().then(newUserList => setUserList(newUserList));
        });
      })
      .catch(() => {
        swal(
          "Ops... Algo deu errado",
          `Não foi possível criar o usuário com o email ${values.email}.`,
          "error"
        );
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="access-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Adicionar Usuário
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="new-user-row">
            <div className="new-user-field">
              <div className="new-user-field-label">
                Nome
                <input
                  className="new-user-field-input"
                  name="firstName"
                  type="text"
                  ref={register({
                    required: "Required"
                  })}
                />
              </div>
            </div>
          </Row>
          {errors.firstName && (
            <Row className="new-user-form-error">Insira o nome do usuário</Row>
          )}
          <Row className="new-user-row">
            <div className="new-user-field">
              <div className="new-user-field-label">
                Sobrenome
                <input
                  className="new-user-field-input"
                  name="lastName"
                  type="text"
                  ref={register({
                    required: "Required"
                  })}
                />
              </div>
            </div>
          </Row>
          {errors.lastName && (
            <Row className="new-user-form-error">
              Insira o sobrenome do usuário
            </Row>
          )}
          <Row className="new-user-row">
            <div className="new-user-field">
              <div className="new-user-field-label">
                Email
                <input
                  className="new-user-field-input"
                  name="email"
                  type="text"
                  ref={register({
                    required: "Required"
                  })}
                />
              </div>
            </div>
          </Row>
          {errors.email && (
            <Row className="new-user-form-error">Insira o email do usuário</Row>
          )}
          <Row className="new-user-row agree">
            <input
              className="agree-checkbox"
              type="checkbox"
              checked={agree}
              onChange={event => setAgree(event.target.checked)}
            ></input>
            <div className="agree-text">
              Eu aceito os{" "}
              <a
                href="https://www.liteme.com.br/litecampus/uso"
                target="_blank"
                rel="noopener noreferrer"
              >
                termos de uso
              </a>
              ,{" "}
              <a
                href="https://www.liteme.com.br/litecampus/seguranca"
                target="_blank"
                rel="noopener noreferrer"
              >
                política de segurança
              </a>{" "}
              e{" "}
              <a
                href="https://www.liteme.com.br/litecampus/privacidade"
                target="_blank"
                rel="noopener noreferrer"
              >
                política de privacidade
              </a>
              .
            </div>
          </Row>
          <Row className="new-user-row submit-btn">
            <Button className="submit-btn" type="submit" disabled={!agree}>
              Adicionar
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

NewUserModal.propTypes = {
  show: PropTypes.bool,
  setModalShow: PropTypes.func,
  onHide: PropTypes.func
};

export default NewUserModal;
