import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import useForm from "react-hook-form";
import weekDaysEnum from "../Enum/week-days.json";
import { Multiselect } from "multiselect-react-dropdown";
import { registerRule, updateRule } from "../../api/Rules";
import { formatRuleToEdit, getRuleDescriptor } from "../../utils/RulesUtils";
import swal from "@sweetalert/with-react";

import "./AddRulesModal.scss";

const AddRulesModal = ({
  actuators,
  show,
  onHide,
  editRule,
  setRuleToUpdate
}) => {
  const { handleSubmit, register, reset } = useForm();

  let [action, setAction] = useState("");
  let [weekDays, setWeekDays] = useState([]);
  let [selectedActuators, setSelectedActuators] = useState([]);

  const multiselectRef = useRef();

  let [allActuators, setAllActuators] = useState(false);

  useEffect(() => {
    reset({});
    if (editRule) {
      setAllActuators(false);

      const rule = formatRuleToEdit(editRule, actuators);
      reset({
        name: rule.name,
        action: rule.action,
        start: rule.start,
        email: rule.email,
        selectedWeekDays: rule.days,
        selectedActuators: rule.actuators
      });

      setAction(rule.action);
      setWeekDays(rule.days);
      setSelectedActuators(rule.actuators);
    }
  }, [editRule]);

  useEffect(() => {
    if (!allActuators && multiselectRef.current)
      multiselectRef.current.resetSelectedValues();
  }, [allActuators]);

  const handleHide = () => {
    setAction("");
    setWeekDays([]);
    setSelectedActuators([]);
    setRuleToUpdate(undefined);
    setAllActuators(false);
    onHide();
  };

  const onSubmit = async ({ name, start, email, action }) => {
    if (weekDays.length > 0 && selectedActuators.length > 0) {
      const descriptor = {
        name: name,
        start: start,
        days: weekDays,
        email: email,
        action: action,
        hardwareIds: selectedActuators
      };

      const ruleDescriptor = getRuleDescriptor(descriptor);
      const registeringRule = editRule
        ? await updateRule(editRule.id, ruleDescriptor)
        : await registerRule(ruleDescriptor);

      if (registeringRule) {
        swal(
          "Alerta adicionado",
          "A regra foi adicionada com sucesso.",
          "success"
        ).then(() => {
          handleHide();
        });
      } else {
        swal(
          "Ops... Algo deu errado",
          "Não foi possível adicionar a regra.",
          "error"
        );
      }
    }
  };

  const handleSetWeekDays = weekDays => {
    setWeekDays(weekDays);
  };

  const handleSetSelectedActuators = selectedActuators => {
    setSelectedActuators(selectedActuators);
  };

  const renderSelectTrigger = () => {
    return (
      <select
        name="action"
        onChange={setAction}
        ref={register({})}
        className={action !== "" ? "" : "placeholder"}
      >
        <option value="" hidden>
          Escolha a ação realizada quando a regra for disparada
        </option>
        <option className="validOption" value={0}>
          Notificar status do(s) atuador(es)
        </option>
        <option className="validOption" value={1}>
          Ligar atuador(es)
        </option>
        <option className="validOption" value={2}>
          Desligar atuador(es)
        </option>
      </select>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {editRule ? "Atualizar Regra" : "Cadastrar Regra"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="alert-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="group">
            <div className="action">AÇÃO</div>
            {renderSelectTrigger()}
            <span className="highlight"></span>
            <span className="bar"></span>
          </div>
          <div className={`modal-form ${action !== "" ? "" : "hidden"}`}>
            <div className="d-flex justify-content-center">
              <div className="group time">
                <input
                  name="name"
                  ref={register({
                    required: "Required"
                  })}
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>NOME</label>
              </div>
              <div className="group time">
                <input
                  name="start"
                  type="time"
                  ref={register({
                    required: "Required"
                  })}
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>HORÁRIO</label>
              </div>
            </div>
            <div className="group select">
              <Multiselect
                name="weekDays"
                options={weekDaysEnum.values}
                selectedValues={weekDays}
                placeholder={"DIAS DA SEMANA"}
                emptyRecordMsg={"Sem dias disponíveis"}
                style={{
                  chips: { background: "#00796b" },
                  optionContainer: {
                    border: "1px solid",
                    borderColor: "#707070"
                  }
                }}
                avoidHighlightFirstOption={true}
                displayValue="day"
                onSelect={handleSetWeekDays}
                onRemove={handleSetWeekDays}
                ref={register({
                  name: "weekDays"
                })}
              />
            </div>
            <div className="group select">
              <Multiselect
                options={actuators}
                selectedValues={selectedActuators}
                name="selectedActuators"
                placeholder={"ATUADORES"}
                emptyRecordMsg={"Sem atuadores disponíveis"}
                style={{
                  chips: { background: "#00796b" },
                  optionContainer: {
                    border: "1px solid",
                    borderColor: "#707070"
                  }
                }}
                avoidHighlightFirstOption={true}
                displayValue="description"
                onSelect={handleSetSelectedActuators}
                onRemove={handleSetSelectedActuators}
                ref={multiselectRef}
              />
            </div>

            <div className="group checkbox">
              <Form.Check
                custom
                name="everyDay"
                className="add-rule-checkbox"
                type="checkbox"
                checked={allActuators}
                onClick={() => setAllActuators(!allActuators)}
                onChange={() =>
                  setSelectedActuators(!allActuators ? actuators : [])
                }
              />
              <div className="actuators-checkbox">TODOS OS ATUADORES</div>
            </div>

            <div className="group">
              <input
                name="email"
                type="email"
                ref={register({
                  required: "Required"
                })}
                placeholder="Por exemplo: dashboard@smartcampus.com"
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="email-input">E-MAIL PARA NOTIFICAÇÃO</label>
              <Button className="rules-button" variant="primary" type="submit">
                {editRule ? "Atualizar" : "Adicionar"}
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

AddRulesModal.propTypes = {
  actuator: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default AddRulesModal;
