import React from "react";
import PropTypes from "prop-types";
import Bars from "../Bars/Bars";
import Label from "../Label/Label";

const CompareConsumptionWidget = ({
  daily,
  average,
  hour,
  type,
  timeType,
  lastMeasurement
}) => {
  const getTime = () => {
    if (!hour) return "Não há dados";
    if (timeType === "today") {
      let end = hour.clone();
      return hour.hour() + " - " + end.add(1, "hours").hour() + "h";
    } else {
      return hour.format("dddd");
    }
  };

  const getDaily = () => {
    if (daily) return daily;
    else if (lastMeasurement) return parseFloat(lastMeasurement[1].toFixed(2));
    else return 0;
  };

  return (
    <>
      <Bars daily={getDaily()} average={average ? average : 0} type={type} />
      <Label
        className="compare-widget"
        title={
          type === "energy"
            ? "Comparação Consumo - kWh"
            : "Comparação Consumo - L"
        }
        type={type}
        subtitle={getTime()}
      />
    </>
  );
};

CompareConsumptionWidget.propTypes = {
  daily: PropTypes.number,
  average: PropTypes.number,
  hour: PropTypes.object,
  type: PropTypes.string,
  timeType: PropTypes.string,
  lastMeasurement: PropTypes.array
};

export default CompareConsumptionWidget;
