const faresData = [
  {
    question: `O que são grupos tarifários?`,
    answer: `De acordo com a tensão fornecida aos consumidores, podemos subdividí-los em dois grupos, os quais denominamos grupos tarifários, que por sua vez são subdivididos em alguns subgrupos menores e mais específicos:

        Grupo A - Grandes consumidores e de alta tensão
        Subgrupos
        - A1 - tensão igual ou acima de 230kV
        - A2 - tensão entre 88kV e 138kV, inclusive
        - A3 - tensão igual a 69kV
        - A3a - tensão entre 30kV e 44kV, inclusive
        - A4 - tensão entre 2,3kV e 25kV, inclusive
        - A5 - tensão inferior a 2,3kV (subterrâneo)
        
        Grupo B - Consumidores comuns e de baixa tensão
        Subgrupos
        - B1 - residencial
        - B1 - residencial baixa renda
        - B2 - rural
        - B2 - cooperativa de eletrificação rural
        - B2 - serviço de irrigação pública
        - B3 - demais classes (industrial, comércio, serviços, serviços públicos, Poder Público, entre outros)
        - B4 - iluminação pública`
  },
  {
    question: `Como funciona a tarifa convencional?`,
    answer: `A tarifa convencional é aplicada independentemente da hora do dia que o consumo foi realizado, isto é, cobra-se um valor fixo em cima do preço do kWh.`
  },
  {
    question: `Como funciona a tarifa horo-sazonal?`,
    answer: `A tarifa horo-sazonal varia o valor dao preço do kWh consumido dependendo da hora do dia e do período do ano. Essa tarifa pode ainda ser subdividida em duas modalidades tarifárias: a tarifa verde, a tarifa azul e a tarifa branca.

        As tarifas verde e azul podem ser contratadas por usuários de alta tensão (Grupo A) e a diferença de uma para a outra é que, no caso da verde, paga-se um valor diferente para a demanda, enquanto na azul esse valor é fixo. Já a tarifa branca é a solução horo-sazonal para usuários de baixa tensão (Grupo B), mas só pode ser contratada por alguns tipos desses consumidores.
        
        De modo geral, essas tarifas cobram preços diferentes para horários de ponta, intermediários e de menor consumo, dependendo de alguns fatores que podem mudar de acordo com a distribuidora de energia, como a faixa de horários considerada de pico e o preço da tarifa.`
  },
  {
    question: `O que são bandeiras tarifárias?`,
    answer: `É uma taxa extra cobrada na conta de energia quando existe uma escassez de água que faz com que as hidrelétricas não produzam tanta energia e as termelétricas precisam suprir essa necessidade. Assim, quanto mais energia vinher das termelétricas, mais cara será a bandeira, já que a energia das termelétricas é mais cara por consumirem combustível.`
  }
];

export default faresData;
