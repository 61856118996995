import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./Bars.scss";

const HexBar = ({ height, type }) => {
  return (
    <div
      style={{ height: height + "px" }}
      className={classNames(["hexbar", type])}
    ></div>
  );
};

HexBar.propTypes = {
  height: PropTypes.number,
  type: PropTypes.string
};

export default HexBar;
