import React, { useEffect, useState } from "react";
import { Form, ListGroup, Modal, Button } from "react-bootstrap";
import { Service } from "../../../api/Service";
import Label from "../../../components/Label/Label";
import useForm from "react-hook-form";
import "./SettingsTab.scss";

const CategoryRow = ({ category }) => {
  return (
    <section className="categories-list-row">
      <div className="category-name">{category}</div>
    </section>
  );
};

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { handleSubmit, register } = useForm();

  const fetchData = () => {
    Service.get("/building/categories").then(({ data }) => {
      const results = {};
      data.forEach(value => {
        results[value.id] = value.categoryName;
      });
      setCategories({ ...results });
    });
  };

  const submitCategory = values => {
    if (values.newcategory !== "") {
      Service.post(`/building/categories?category=${values.newcategory}`).then(
        () => {
          fetchData();
        }
      );
    }
    setShowModal(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const items = Object.values(categories).map(category => {
    return <CategoryRow key={category} category={category} />;
  });

  const renderCategories = () => {
    if (categories.length === 0)
      return (
        <Label
          title={`Clique em "Criar nova categoria" para criar a primeira categoria`}
        ></Label>
      );
    else return <ListGroup className="categories">{items}</ListGroup>;
  };

  return (
    <>
      <section className="add-user-row">
        <div className="add-user-col">
          <div className="add-user-btn" onClick={() => setShowModal(true)}>
            Criar nova categoria
          </div>
        </div>
      </section>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="access-modal"
        centered
      >
        <Modal.Header closeButton onClick={() => setShowModal(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            Adicionar Categoria
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit(submitCategory)}>
            <section style={{ width: "100%" }}>
              <input
                name="newcategory"
                type="text"
                ref={register({
                  required: "Required"
                })}
              />
            </section>
            <Button className="submit-btn-category" type="submit">
              Adicionar categoria
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <section className="categories-list">
        <section className="categories-list-header">Nome</section>
        <section className="categories-list-content">
          {renderCategories()}
        </section>
      </section>
    </>
  );
};

export default Categories;
