import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import useConsumptionOrMagnituteFilterStore from "../../../stores/useConsumptionOrMagnituteFilterStore";
import useIsMagnituteFilterOn from "../../../hooks/useIsMagnituteFilterOn";
import useIsConsumptionFilterOn from "../../../hooks/useIsConsumptionFilterOn";
import "./ConsumptionOrMagnitudeSelector.scss";

const ConsumptionOrMagnitudeSelector = () => {
  const {
    setMagnituteFilterOn,
    setConsumptionFilterOn
  } = useConsumptionOrMagnituteFilterStore();

  const isConsumptionFilterOn = useIsConsumptionFilterOn();
  const isMagnituteFilterOn = useIsMagnituteFilterOn();

  return (
    <ButtonGroup
      aria-label="Seletor de Consumo ou Grandeza"
      className="consumption-magnitude-selector-container"
    >
      <Button
        className={isConsumptionFilterOn ? "selected" : ""}
        onClick={setConsumptionFilterOn}
      >
        Consumo
      </Button>
      <Button
        className={isMagnituteFilterOn ? "selected" : ""}
        onClick={setMagnituteFilterOn}
      >
        Grandeza
      </Button>
    </ButtonGroup>
  );
};

export default ConsumptionOrMagnitudeSelector;
