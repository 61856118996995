import * as React from "react";
import { Col } from "react-bootstrap";

const VRMSIcon = ({ isActive, ...props }) => (
  <Col xs={5} className="equatorial-measurement-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <g data-name="Grupo 2387">
        <g fill="#fff" stroke="#5d5d5d" data-name="Elipse 2">
          <circle cx={16} cy={16} r={16} stroke="none" />
          <circle
            cx={16}
            cy={16}
            r={15.5}
            fill={isActive ? "#5D5D5D" : "none"}
          />
        </g>
        <path
          fill={isActive ? "white" : "#5D5D5D"}
          d="M24.671 10.054v.068a2.576 2.576 0 0 1-2.57 2.576h-5.53v6.81l1.783 1.032a.323.323 0 0 1 .137.137.35.35 0 0 1 .05.185v2.48a.35.35 0 0 1-.05.185.323.323 0 0 1-.137.137l-2.143 1.246a.393.393 0 0 1-.185.05.389.389 0 0 1-.187-.05s-1.947-1.127-2.15-1.241a.368.368 0 0 1-.135-.137.35.35 0 0 1-.05-.185v-2.48a.35.35 0 0 1 .05-.185.368.368 0 0 1 .135-.137c.147-.083 1.216-.7 1.789-1.032v-7.359a.545.545 0 0 1 .548-.546h6.077a1.482 1.482 0 0 0 1.476-1.486v-.071a1.473 1.473 0 0 0-1.476-1.478H7.919a.547.547 0 1 1 0-1.094h14.184a2.568 2.568 0 0 1 1.816.756 2.542 2.542 0 0 1 .755 1.816"
          data-name="Caminho 4710"
        />
      </g>
    </svg>
  </Col>
);
export default VRMSIcon;
