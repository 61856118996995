import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "react-bootstrap/ButtonGroup";
//import Button from "react-bootstrap/Button";
import "./MarkerSelector.scss";

const MarkerSelector = () => {
  return (
    <ButtonGroup
      aria-label="Seletor de marcador"
      className="marker-selector-container"
    >
      {/* <Button
        className={marker === "alerts" ? "selected" : ""}
        onClick={() => onMarkerChange("alerts")}
      >
        Alertas
      </Button>
      <Button
        className={marker === "consumption" ? "selected" : ""}
        onClick={() => onMarkerChange("consumption")}
      >
        Consumo
      </Button> */}
    </ButtonGroup>
  );
};

MarkerSelector.propTypes = {
  marker: PropTypes.string.isRequired,
  onMarkerChange: PropTypes.func.isRequired
};

export default MarkerSelector;
