import React, { useEffect, useState } from "react";
import { postTracking } from "../../../api/Tracking";
import AlertList from "../../../components/AlertList/AlertList";
import AreaHeader from "../../../components/AreaHeader/AreaHeader";
import alertTriggersEnum from "../../../components/Enum/alert-triggers.json";
import "../../Dashboard.scss";
import "./AlertsTab.scss";
import useGetFilteredAlerts from "./hooks/useGetFilteredAlerts";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";

const ALERTS_PER_PAGE = 15;

const AlertsTab = ({
  area,
  admin,
  showAll,
  onAlertClick,
  allAreasData,
  onShowAllClick,
  isReadOnlyUser
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const { buildingId } = area || {};

  const buildingIds = allAreasData?.areas?.map(area => area?.buildingId);
  const { alerts, totalSize, refetch } = useGetFilteredAlerts({
    buildingIds: buildingId || buildingIds,
    limit: ALERTS_PER_PAGE,
    page: currentPage
  });

  const allTriggersValues = Object.keys(alertTriggersEnum).map(key => ({
    field: key,
    label: alertTriggersEnum[key].label
  }));

  const [triggers, setTriggers] = useState([
    { label: "Todos", field: "all" },
    ...allTriggersValues
  ]);

  useEffect(() => {
    alerts.forEach(alert => {
      if (alert?.trigger) {
        const alertTrigger = {
          label: alertTriggersEnum[alert?.trigger?.type]?.label,
          field: alert?.trigger?.type
        };

        const triggersHasAlert = triggers.some(
          trigger => trigger.field === alertTrigger.field
        );

        if (!triggersHasAlert) {
          setTriggers([...triggers, alertTrigger]);
        }
      }
    });
  }, [alerts]);

  const handlePaginate = event => setCurrentPage(event.selected);

  useEffect(() => {
    postTracking("MAPS-ALERTS");
  }, []);

  return (
    <>
      <section className="header">
        <AreaHeader
          areaName={area?.name}
          areaUpperName={process.env.REACT_APP_CAMPUS}
          onUpperNameClick={onShowAllClick}
          showAll={showAll}
          typeMeter="medição"
          showBackButton={allAreasData?.areas?.length > 1}
          prefix="Alertas disparados nos últimos 30 dias - "
        />
      </section>
      {admin && (
        <section className="list-row">
          <AlertList
            alerts={alerts}
            onAlertClick={onAlertClick}
            buildingId={area?.buildingId}
            buildings={allAreasData?.areas}
            isReadOnlyUser={isReadOnlyUser}
            triggers={triggers}
            refetch={refetch}
          />
          <CustomPagination
            currentPage={currentPage}
            totalItems={totalSize}
            pageSize={ALERTS_PER_PAGE}
            handlePaginate={handlePaginate}
          />
        </section>
      )}
    </>
  );
};

export default AlertsTab;
