import { useQuery } from "@tanstack/react-query";
import defaultQueryFn from "../../../../api/defaultQueryFn";

const useAlertsQuery = ({
  buildingIds = [],
  start,
  end,
  limit = 10,
  offset = 0,
  read = undefined,
  archived = undefined,
  types = undefined,
  mitigated = undefined,
  triggers = undefined
}) => {
  const params = {
    buildingIds,
    start,
    end,
    limit,
    offset
  };

  if (read != undefined) params.read = read;
  if (archived != undefined) params.archived = archived;
  if (types != undefined) params.types = types;
  if (mitigated != undefined) params.mitigated = mitigated;
  if (triggers != undefined) params.triggers = triggers;

  const query = useQuery({
    queryKey: ["/withoutauth/buildings/sensors/alerts/logs", params],
    queryFn: defaultQueryFn,
    cacheTime: 5,
    retry: 0,
    staleTime: 0
  });

  return query;
};

export default useAlertsQuery;
