import React from "react";
import ReactPaginate from "react-paginate";
import "./CustomPagination.scss";

const CustomPagination = ({ totalItems, pageSize, handlePaginate }) => {
  const pageCount = Math.ceil(totalItems / pageSize);

  if (totalItems < 1) return null;
  return (
    <ReactPaginate
      containerClassName={"pagination"}
      pageLinkClassName={"page-item"}
      previousLinkClassName={"page-item"}
      nextLinkClassName={"page-item"}
      breakLinkClassName={"page-item break"}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      activeClassName={"active"}
      onPageChange={handlePaginate}
      pageCount={pageCount}
      breakLabel="..."
      previousLabel="< Anterior"
      nextLabel="Próxima >"
      renderOnZeroPageCount={null}
    />
  );
};

export default CustomPagination;
