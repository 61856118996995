import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    alerts: state.data ? (state.data.all ? state.data.all.alerts : []) : []
  };
};

const ChangeTabButton = ({ area, alerts, currentTab, tab, onTabChange }) => {
  const [alertsCount, setAlertsCount] = useState(0);

  useEffect(() => {
    setAlertsCount(
      area.buildingId === ""
        ? alerts.length
        : alerts.filter(alert => alert.buildingId === area.buildingId).length
    );
  }, [alerts, area]);

  const getButtonProperties = () => {
    let buttonProperties =
      "change-type-button " +
      tab +
      (tab === currentTab ? " active" : " inactive");
    if (tab === "alerts" && alertsCount > 0)
      buttonProperties = buttonProperties + " has-alerts";
    return buttonProperties;
  };

  return (
    <div
      className={getButtonProperties()}
      onClick={() => onTabChange(tab)}
    ></div>
  );
};

ChangeTabButton.propTypes = {
  area: PropTypes.object,
  alerts: PropTypes.array,
  currentTab: PropTypes.string,
  tab: PropTypes.string,
  onTabChange: PropTypes.func
};

export default connect(mapStateToProps)(ChangeTabButton);
