import useAllDataForSelectedBuilding from "./useAllDataForSelectedBuilding";
import useGetMeasurementUtils from "./useGetMeasurementUtils";

const useGetSeriesForSelectedMeasurement = () => {
  const buildingData = useAllDataForSelectedBuilding();
  const { hasNeutralValue, hasTotalValue } = useGetMeasurementUtils();

  const { series: seriesData } = buildingData || [];

  const phaseA = seriesData?.map(({ timestamp, phases }) => [
    timestamp,
    phases?.a
  ]);
  const phaseB = seriesData?.map(({ timestamp, phases }) => [
    timestamp,
    phases?.b
  ]);
  const phaseC = seriesData?.map(({ timestamp, phases }) => [
    timestamp,
    phases?.c
  ]);

  const value = seriesData?.map(({ timestamp, value }) => [timestamp, value]);

  const commomSeriesProps = {
    type: "spline",
    marker: {
      enabled: false
    },
    lineWidth: 3,
    states: {
      hover: {
        lineWidth: 2
      }
    }
  };

  const series = [
    {
      ...commomSeriesProps,
      id: "phase-a",
      name: "Fase A",
      color: "#000000",
      data: phaseA
    },
    {
      ...commomSeriesProps,
      id: "phase-b",
      name: "Fase B",
      color: "#f21313",
      data: phaseB
    },
    {
      ...commomSeriesProps,
      id: "phase-c",
      name: "Fase C",
      color: "#a67646",
      data: phaseC
    }
  ];

  if (hasNeutralValue) {
    series.push({
      ...commomSeriesProps,
      id: "neutral",
      name: "Neutro",
      color: "#0178ff",
      data: value
    });
  }

  if (hasTotalValue) {
    series.push({
      ...commomSeriesProps,
      id: "total",
      name: "Total",
      color: "#f8be22",
      data: value
    });
  }

  return series;
};

export default useGetSeriesForSelectedMeasurement;
