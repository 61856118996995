import React, { Component } from "react";
import $ from "jquery";
import "./List.scss";

class List extends Component {
  scroll(direction) {
    let far = ($(".content_container").width() / 2) * direction;
    let pos = $(".content_container").scrollLeft() + far;
    $(".content_container").animate({ scrollLeft: pos }, 500);
  }

  render() {
    return (
      <div className={`list ${this.props.type}`}>
        <a className="prev" onClick={this.scroll.bind(null, -1)}>
          &#9664;
        </a>
        <div className="content_container">{this.props.children}</div>
        <a className="next" onClick={this.scroll.bind(null, 1)}>
          &#9654;
        </a>
      </div>
    );
  }
}
export default List;
