import React from "react";
import PropTypes from "prop-types";
import LoginSmartiks from "./LoginSmartiks";
import "./Login.scss";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { signIn } from "../../actions/auth";

const mapStateToProps = state => {
  return {
    user: state.auth
  };
};

const Login = () => {
  return (
    <div>
      <Container className="login-container">
        <LoginSmartiks />
      </Container>
    </div>
  );
};

Login.propTypes = {
  signIn: PropTypes.func
};

export default connect(mapStateToProps, { signIn })(Login);
