const idMapping = {
  // CAA

  AUX_CH: "5d9f3f344f0c7a05bbfb7dc9",
  AUX_DDE: "5d9f3f344f0c7a05bbfb7dc9",
  AUX_CL: "5d9f3f344f0c7a05bbfb7dc9",

  //LSD

  AUX_CK: "54c9be5ae4b0411fff10e8a8",
  AUX_CA: "54c9be5ae4b0411fff10e8a8",
  AUX_CG: "54c9be5ae4b0411fff10e8a8",
  AUX_CD1: "54c9be5ae4b0411fff10e8a8",
  AUX_ESTUFA3: "54c9be5ae4b0411fff10e8a8",

  // CAA TERREO

  AUX_CT: "5d9f40224f0c7a05bbfb8209",
  AUX_CEEI: "5d9f40224f0c7a05bbfb8209",

  // CERTBIO

  AUX_ALIMENTOS: "5ce2de324f0c8ba7b1da9c43",
  AUX_REENGE: "5ce2de324f0c8ba7b1da9c43",
  AUX_DONA_INES: "5ce2de324f0c8ba7b1da9c43",
  AUX_CQ: "5ce2de324f0c8ba7b1da9c43",
  AUX_EMBEDDED: "5ce2de324f0c8ba7b1da9c43",

  // CN

  AUX_CD: "5cb4c2f34f0cc9a612229474",
  AUX_ESTUFA: "5cb4c2f34f0cc9a612229474",
  AUX_CM: "5cb4c2f34f0cc9a612229474",
  AUX_METEOROLOGIA: "5cb4c2f34f0cc9a612229474",
  AUX_CX: "5cb4c2f34f0cc9a612229474",
  AUX_CR: "5cb4c2f34f0cc9a612229474",

  // CITTA

  AUX_CV: "5fd8e559e4b0e13bf58963ca",
  AUX_INDUSTRIAL: "5fd8e559e4b0e13bf58963ca",
  AUX_CJ: "5fd8e559e4b0e13bf58963ca",
  AUX_CZ: "5fd8e559e4b0e13bf58963ca",
  AUX_LAT: "5fd8e559e4b0e13bf58963ca",
  AUX_CS: "5fd8e559e4b0e13bf58963ca",
  AUX_SPLAB: "5fd8e559e4b0e13bf58963ca"
};

export const getRealBuildingId = buildingId => {
  if (idMapping[buildingId]) return idMapping[buildingId];
  else return buildingId;
};
