import React from "react";
import Chart from "../../../components/Chart/Chart";
import { Col, Row } from "react-bootstrap";
import useFormatEquatorialValues from "./hooks/useFormatEquatorialValues";
import useGetSeriesForSelectedMeasurement from "./hooks/useGetSeriesForSelectedMeasurement";
import useSelectedMeasurementStore from "./stores/useSelectedMeasurementStore";
import useTimeTypeStore from "../../../components/Dashboard/stores/useTimeTypeStore";
import moment from "moment";

const EquatorialEnergyChart = () => {
  const series = useGetSeriesForSelectedMeasurement();

  const selectedMeasurement = useSelectedMeasurementStore(
    state => state.selectedMeasurement
  );

  const unit = selectedMeasurement.unit || "";

  const timeType = useTimeTypeStore(state => state.timeType);

  const { formatValue } = useFormatEquatorialValues();

  const options = {
    chart: {
      style: {
        fontFamily: "Ubuntu"
      },
      height: "45%"
    },

    title: {
      text: ""
    },

    credits: false,

    yAxis: {
      title: {
        text: ""
      },

      plotLines: [
        {
          value: 0,
          width: 1,
          color: "#808080"
        }
      ],

      minorTickLength: 0,
      tickLength: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      endOnTick: false
    },

    xAxis: {
      title: {
        text: ""
      },

      labels: {
        formatter: function() {
          return timeType === "today"
            ? moment(this.value).format("HH:mm")
            : moment(this.value).format("DD/MM");
        }
      },

      tickInterval: timeType === "today" ? 3600 * 1000 * 2 : null,

      plotLines: [
        {
          value: 0,
          width: 1,
          color: "#808080"
        }
      ],

      minorTickLength: 0,
      tickLength: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      endOnTick: false
    },

    plotOptions: {
      line: {
        stacking: "normal",
        borderWidth: 1
      },
      series: {
        pointWidth: 10
      }
    },

    tooltip: {
      enabled: true,
      padding: 15,
      style: {
        fontSize: "1.2rem"
      },
      headerFormat: null,
      pointFormatter: function() {
        return `${
          timeType === "today"
            ? moment(this.x).format("HH:mm")
            : moment(this.x).format("DD/MM")
        }: <b>${formatValue(this.y)} ${unit}</b><br/>`;
      },
      shared: false
    },

    legend: {
      enabled: false
    },

    exporting: {
      enabled: false
    },

    series: series
  };

  return (
    <Row className="equatorial-energy-graph">
      <Col xs={12}>
        <Chart options={options} />
      </Col>
    </Row>
  );
};

export default EquatorialEnergyChart;
