import weekDaysEnum from "../components/Enum/week-days.json";
import rulesActionsEnum from "../components/Enum/rules-actions.json";
import moment from "moment";

const endOfDay = 86399999;

const parseToStringHour = stringMilliseconds => {
  const date = new Date(stringMilliseconds);
  const GMT = 3;
  const hours = date.getHours() + GMT;
  const minutes = date.getMinutes();
  return `${(hours < 10 ? "0" : "") + hours}:${(minutes < 10 ? "0" : "") +
    minutes}`;
};

const getActionIndex = actions => {
  if (actions.length === 1) return 0;
  else if (actions[1].type === "SET_ACTUATORS_STATUS_ON") return 1;
  else if (actions[1].type === "SET_ACTUATORS_STATUS_OFF") return 2;
};

const getWeekDays = days => {
  return weekDaysEnum.values.filter(value => days.includes(value.id));
};

const getActuators = (hardwareIds, actuators) => {
  return actuators.filter(actuator =>
    hardwareIds.includes(actuator.hardware_id)
  );
};

const parseToMilliseconds = stringHour => {
  const date = moment(stringHour, "HH:mm").toDate();
  const GMT = -3;
  date.setHours(date.getHours() + GMT);

  const minutes = date.getMinutes();
  const hours = date.getHours();
  return (hours * 60 + minutes) * 60 * 1000;
};

export const formatRuleToEdit = (rule, actuators) => {
  return {
    action: getActionIndex(rule.actions),
    name: rule.name,
    start: parseToStringHour(rule.start),
    email: rule.actions[0].addresses[0],
    days: getWeekDays(rule.frequency.data),
    actuators: getActuators(rule.hardwareIds, actuators)
  };
};

export const getRuleDescriptor = descriptor => {
  const action = rulesActionsEnum.values[descriptor.action];

  return {
    name: descriptor.name,
    start: parseToMilliseconds(descriptor.start),
    end: endOfDay,
    interval: Math.abs(parseToMilliseconds(descriptor.start) - endOfDay),
    days: descriptor.days.map(day => day.id),
    email: descriptor.email,
    action: action.action ? action.action : null,
    hardwareIds: descriptor.hardwareIds.map(actuator => actuator.hardware_id),
    trigger: action.trigger
  };
};

export const getDescriptorFromRule = rule => {
  const startString = parseToStringHour(rule.start);
  const start = parseToMilliseconds(startString);

  return {
    enabled: rule.enabled,
    hardwareIds: rule.hardwareIds,
    name: rule.name,
    email: rule.actions[0].addresses[0],
    trigger: rule.trigger.type,
    action: rule.actions[1] ? rule.actions[1].type : null,
    days: rule.frequency.data,
    start: start,
    interval: Math.abs(start - endOfDay),
    end: endOfDay
  };
};
