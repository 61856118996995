const actuatorsData = [
  {
    question: `Meu ar condicionado não ligou, o que eu faço?`,
    answer: `Você pode então apertar no botão ao lado dos leds. Se o botão não estiver acessível, veja se ele está desligado no dashboard, se estiver, talvez ele tenha sido programado para não ficar habilitado no horário.`
  },
  {
    question: `Meu aparelho não religa depois que desligo ele pelo LiteCampus, como configuro isso?`,
    answer: `Para os circuitos de ar condicionado, associar a ativação do atuador ao religamento do aparelho de ar condicionado pode exigir configuração. 
    Para aparelhos mais antigos (por exemplo, que podem ser controlados diretamente por interruptores), a reativação de um aparelho que foi desligado também via atuador é automática. Para aparelhos mais novos, há três possibilidades: 

    1. o aparelho retorna por padrão para o último estado (ou seja, se foi desligado via atuador, será religado também pelo atuador); 
    2. o aparelho não retorna por padrão na configuração de fábrica, neste caso, após um religamento, o aparelho se desliga automaticamente; para resolver este caso é necessário que seja feito uma configuração única da funcionalidade tipicamente chamada de “Reinício automático” utilizando o controle remoto do aparelho (detalhes são específicos para cada modelo e marca); 
    3. por padrão, após um religamento, o aparelho se desliga automaticamente, necessitando que seja feito uma ativação da configuração chamada de “Reinício automático” através de uma chave (ou jumper) dentro do aparelho. Esta configuração fará parte do protocolo de instalação de um atuador."`
  }
];

export default actuatorsData;
