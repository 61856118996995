import * as React from "react";
import { Col } from "react-bootstrap";

const IRMSIcon = ({ isActive, ...props }) => (
  <Col xs={5} className="equatorial-measurement-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <g data-name="Grupo 2387">
        <g fill="#fff" stroke="#5d5d5d" data-name="Elipse 2">
          <circle cx={16} cy={16} r={16} stroke="none" />
          <circle
            cx={16}
            cy={16}
            r={15.5}
            fill={isActive ? "#5D5D5D" : "none"}
          />
        </g>
        <path
          fill={isActive ? "white" : "#5D5D5D"}
          d="M23.857 24.238a.549.549 0 0 1-.548.546h-8.107a8.523 8.523 0 0 1-4.014-1c-.556.322-1.932 1.117-1.938 1.117a.383.383 0 0 1-.185.056.417.417 0 0 1-.187-.056S6.931 23.78 6.734 23.66a.334.334 0 0 1-.137-.131.359.359 0 0 1-.048-.185v-2.481a.371.371 0 0 1 .048-.187.357.357 0 0 1 .131-.131c.077-.046.407-.235.785-.455a8.658 8.658 0 0 1 7.688-12.607h8.107a.547.547 0 1 1 0 1.094h-8.107a7.544 7.544 0 0 0-6.739 10.965l.415-.237a.417.417 0 0 1 .187-.056.383.383 0 0 1 .185.056l2.15 1.241a.388.388 0 0 1 .137.131.372.372 0 0 1 .05.187v1.9a7.485 7.485 0 0 0 3.615.928h8.107a.55.55 0 0 1 .548.548"
          data-name="Caminho 4471"
        />
      </g>
    </svg>
  </Col>
);
export default IRMSIcon;
