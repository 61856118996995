import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEnvelopeOpen,
  faTrash,
  faFolder,
  faFolderOpen,
  faCheckCircle,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import "./AlertRow.scss";
import { updateAlert, deleteAlert } from "../../api/Alerts";
import swal from "@sweetalert/with-react";

const AlertRow = ({
  alert,
  onAlertClick,
  building,
  refetch,
  isReadOnlyUser
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const [read, setRead] = useState(alert?.read);
  const [mitigated, setMitigated] = useState(alert?.mitigated);
  const [archived, setArchived] = useState(alert?.archived);
  const [disabled] = useState(isReadOnlyUser);

  useEffect(() => {
    if (
      read !== alert?.read ||
      archived !== alert?.archived ||
      mitigated !== alert?.mitigated
    ) {
      handleUpdateAlert(alert?.id, read, archived, mitigated);
    }
  }, [read, archived, mitigated]);

  const timeFormatter = timestamp => {
    return moment(new Date(timestamp)).format("HH[h] DD/MM/YYYY");
  };

  const removeAlert = () => {
    swal({
      title: "Remover alerta?",
      text: "Tem certeza que deseja remover o Alerta?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        handleDeleteAlert(alert?.id);
      } else {
        swal("Alerta não removido", "O alerta não foi removido!", "info");
      }
    });
  };

  const handleDeleteAlert = async id => {
    const deletingAlert = await deleteAlert(id);
    if (deletingAlert) {
      setTimeout(() => refetch(), 2000);
      swal("Alerta removido", "O alerta foi removido com sucesso.", "success");
    } else {
      swal(
        "Ops... Algo deu errado",
        "Não foi possível remover o alerta",
        "error"
      );
    }
  };

  const handleUpdateAlert = async (id, read, archived, mitigated) => {
    await updateAlert(id, read, archived, mitigated).then(alert => {
      if (alert) setTimeout(() => refetch(), 2000);
    });
  };

  return (
    <div>
      <Row
        className={
          "alert-header " +
          (read ? "read" : "unread") +
          (archived ? " archived" : "") +
          (mitigated ? " mitigated" : "")
        }
      >
        <Col
          md={3}
          className={"text building"}
          onClick={() => {
            onAlertClick(alert?.buildingId);
            setShowDescription(!showDescription);
          }}
        >
          <Row>{building?.name}</Row>
        </Col>

        <Col
          md={6}
          className={"text"}
          onClick={() => {
            onAlertClick(alert?.buildingId);
            setShowDescription(!showDescription);
          }}
        >
          <Row>{timeFormatter(alert?.timestamp)}</Row>
          <Row className={"title"}>{alert?.title}</Row>
        </Col>

        <Col md={3} className={"button-group"}>
          <FontAwesomeIcon
            title={mitigated ? "Marcar como não mitigado" : "Mitigar"}
            className={"button" + (disabled ? " disabled-btn" : "")}
            icon={mitigated ? faCheckCircle : faCircle}
            size="lg"
            onClick={disabled ? undefined : () => setMitigated(!mitigated)}
          />
          <FontAwesomeIcon
            title={read ? "Marcar como não lido" : "Marcar como lido"}
            className={"button" + (disabled ? " disabled-btn" : "")}
            icon={read ? faEnvelopeOpen : faEnvelope}
            size="lg"
            onClick={disabled ? undefined : () => setRead(!read)}
          />
          <FontAwesomeIcon
            title={archived ? "Desarquivar" : "Arquivar"}
            className={"button" + (disabled ? " disabled-btn" : "")}
            icon={archived ? faFolderOpen : faFolder}
            size="lg"
            onClick={disabled ? undefined : () => setArchived(!archived)}
          />
          <FontAwesomeIcon
            title="Excluir"
            className={"button" + (disabled ? " disabled-btn" : "")}
            icon={faTrash}
            size="lg"
            onClick={disabled ? undefined : () => removeAlert()}
          />
        </Col>
      </Row>
      {showDescription && (
        <Row className={"alert-description " + (read ? "read" : "unread")}>
          <Col className={"content"}>
            <Row className={"title " + (read ? "read" : "unread")}>
              {alert?.title}
            </Row>
            <Row>{alert?.content}</Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

AlertRow.propTypes = {
  alert: PropTypes.object,
  onAlertClick: PropTypes.func,
  building: PropTypes.object,
  alerts: PropTypes.array,
  filterItems: PropTypes.func
};

export default AlertRow;
