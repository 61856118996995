import { FETCH_SENSORS } from "../actions/types";

const INITIAL_STATE = {
  sensors: [
    {
      id: "3cac121f-3031-4035-babd-252581ad49fb",
      coords: { lat: -7.21584, lng: -35.909356 },
      count: 9
    },
    {
      id: "d887f903-783f-4883-bd72-d391373c91a4",
      coords: { lat: -7.21339, lng: -35.907319 },
      count: 21
    },
    {
      id: "d61c96b0-3170-4a15-b775-ae7506b0eec0",
      coords: { lat: -7.216067, lng: -35.906827 },
      count: 17
    }
  ]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SENSORS:
      return {
        ...state,
        sensors: action.payload
      };
    default:
      return state;
  }
};
