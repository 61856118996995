const filterOptions = {
  order: [
    {
      label: "Alfabética",
      field: "alphabetical"
    },
    {
      label: "Alfabética Invertida",
      field: "reverse-alphabetical"
    }
  ],
  status: [
    {
      label: "Ligado",
      field: "on"
    },
    {
      label: "Desligado",
      field: "off"
    }
  ],
  availability: [
    {
      label: "Disponível",
      field: "on"
    },
    {
      label: "Indisponível",
      field: "off"
    }
  ]
};

const ActuatorsFilters = [
  {
    label: "Ordem",
    field: "order",
    options: filterOptions["order"],
    multipleOptions: false
  },
  {
    label: "Status",
    field: "status",
    options: filterOptions["status"],
    multipleOptions: true
  },
  {
    label: "Disponibilidade",
    field: "availability",
    options: filterOptions["availability"],
    multipleOptions: true
  }
];

export default ActuatorsFilters;
