import * as FAQ from "./FAQTopics";

const FAQData = {
  general: {
    title: "Geral",
    faqs: FAQ.GeneralData
  },
  actuators: {
    title: "Atuação",
    faqs: FAQ.ActuatorsData
  },
  fundamentals: {
    title: "Fundamentos",
    faqs: FAQ.FundamentalsData
  },
  specifics: {
    title: "Conceitos Específicos",
    faqs: FAQ.SpecificsData
  },
  fares: {
    title: "Tarifas",
    faqs: FAQ.FaresData
  },
  tips: {
    title: "Dicas",
    faqs: FAQ.TipsData
  },
  termsAndConditions: {
    title: "Termos e Políticas",
    faqs: FAQ.TermsAndConditionsData
  }
};

const FAQNavConfig = [
  {
    value: "general",
    label: "Geral"
  },
  {
    value: "actuators",
    label: "Atuação"
  },
  {
    value: "fundamentals",
    label: "Fundamentos"
  },
  {
    value: "specifics",
    label: "Conceitos Específicos"
  },
  {
    value: "fares",
    label: "Tarifas"
  },
  {
    value: "tips",
    label: "Dicas"
  },
  {
    value: "termsAndConditions",
    label: "Termos e Políticas"
  }
];

export { FAQData, FAQNavConfig };
