import { SIGN_IN, SIGN_OUT } from "./types";
import { logout } from "../api/Auth";

export const signIn = user => dispatch => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("isSignedIn", JSON.stringify(true));
  dispatch({ type: SIGN_IN, payload: user });
  window.location.href = "/";
};

export const signOut = () => dispatch => {
  logout();
  localStorage.removeItem("user");
  localStorage.removeItem("AuthData");
  localStorage.removeItem("AuthToken");
  localStorage.setItem("isSignedIn", JSON.stringify(false));
  dispatch({ type: SIGN_OUT });
  window.location.href = "/";
};

export const tryAutoConnect = () => dispatch => {
  const isSignedIn = JSON.parse(localStorage.getItem("isSignedIn"));
  if (!isSignedIn) {
    dispatch({ type: SIGN_OUT });
  } else {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch({ type: SIGN_IN, payload: user });
  }
};
