import React from "react";
import PropTypes from "prop-types";

const SideMenuBody = ({ children }) => {
  return <div className="body-container">{children}</div>;
};

SideMenuBody.propTypes = {
  children: PropTypes.element
};

export default SideMenuBody;
