import React from "react";
import SideMenuHeader from "./SideMenuHeader";
import SideMenuBody from "./SideMenuBody";
import PropTypes from "prop-types";
import Tabs from "../../Dashboard/Tabs/Tabs";
import "./SideMenu.scss";

const SideMenu = ({ area, children, tab, setTab, closed }) => {
  return (
    <div className="side-menu-container">
      <div className={"right-column " + (closed ? "closed" : "")}>
        <div className="right-column-container">
          <SideMenuHeader
            area={area}
            tabs={Tabs}
            currentTab={tab}
            onTabChange={setTab}
          ></SideMenuHeader>
          <SideMenuBody>
            {children ? (
              React.cloneElement(children, { tab: tab })
            ) : (
              <div></div>
            )}
          </SideMenuBody>
        </div>
      </div>
    </div>
  );
};

SideMenu.propTypes = {
  children: PropTypes.element
};

export default SideMenu;
