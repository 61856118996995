import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faMinus
} from "@fortawesome/free-solid-svg-icons";

const aToZ = {
  accessor: "a-to-z",
  label: "A-Z",
  title: "Ordenar pontos alfabéticamente",
  width: 1,
  component: <div className="detailed-filter-text">A-Z</div>,
  sortFunction: (a, b) => (a?.name > b?.name ? 1 : a?.name === b?.name ? 0 : -1)
};

const highestConsumption = {
  accessor: "highest-consumption",
  label: "Maior consumo",
  title: "Ordenar pelo maior consumo mensal",
  width: 2,
  component: <div className="detailed-filter-text">Maior consumo</div>,
  sortFunction: (a, b) =>
    a?.totalConsumption?.month < b?.totalConsumption?.month
      ? 1
      : a?.totalConsumption?.month === b?.totalConsumption?.month
      ? 0
      : -1
};

const lowestConsumption = {
  accessor: "lowest-consumption",
  label: "Menor consumo",
  title: "Ordenar pelo menor consumo mensal",
  width: 2,
  component: <div className="detailed-filter-text">Menor consumo</div>,
  sortFunction: (a, b) =>
    a?.totalConsumption?.month > b?.totalConsumption?.month
      ? 1
      : a?.totalConsumption?.month === b?.totalConsumption?.month
      ? 0
      : -1
};

const increasingConsumption = {
  accessor: "increasing-consumption",
  label: "Consumo aumentando",
  title: "Consumo atual acima da média móvel",
  description:
    "Comparação realizada entre a média dos últimos 7 dias com os 21 dias anteriores.",
  width: 1,
  component: (
    <div className="status-indicator-wrapper status-indicator-wrapper-filter">
      <FontAwesomeIcon
        className="status-indicator status-indicator-filter"
        icon={faArrowUp}
        size="xs"
      />
    </div>
  ),
  filterFunction: a => a?.status?.status === "above",
  mapFilterFunction: a => a?.properties?.status === "above"
};

const decreasingConsumption = {
  accessor: "decreasing-consumption",
  label: "Consumo diminuindo",
  title: "Consumo atual abaixo da média móvel",
  description:
    "Comparação realizada entre a média dos últimos 7 dias com os 21 dias anteriores.",
  width: 1,
  component: (
    <div className="status-indicator-wrapper status-indicator-wrapper-filter">
      <FontAwesomeIcon
        className="status-indicator status-indicator-filter"
        icon={faArrowDown}
        size="xs"
      />
    </div>
  ),
  filterFunction: a => a?.status?.status === "below",
  mapFilterFunction: a => a?.properties?.status === "below"
};

const stableConsumption = {
  accessor: "normal-consumption",
  label: "Consumo estável",
  title: "Consumo atual próximo da média móvel",
  description:
    "Comparação realizada entre a média dos últimos 7 dias com os 21 dias anteriores.",
  width: 1,
  component: (
    <div className="status-indicator-wrapper status-indicator-wrapper-filter">
      <FontAwesomeIcon
        className="status-indicator status-indicator-filter"
        icon={faMinus}
        size="xs"
      />
    </div>
  ),
  filterFunction: a => a?.status?.status === "normal",
  mapFilterFunction: a => a?.properties?.status === "normal"
};

const EnergyDetailedFilters = [
  aToZ,
  highestConsumption,
  lowestConsumption,
  increasingConsumption,
  decreasingConsumption,
  stableConsumption
];

const EnergyDetailedFiltersMapping = {
  "a-to-z": aToZ,
  "highest-consumption": highestConsumption,
  "lowest-consumption": lowestConsumption,
  "increasing-consumption": increasingConsumption,
  "decreasing-consumption": decreasingConsumption,
  "normal-consumption": stableConsumption
};

export { EnergyDetailedFilters, EnergyDetailedFiltersMapping };
