import React, { useState } from "react";
import PropTypes from "prop-types";
import List from "../List/List";
import Alert from "../Alerts/Alert";
import Label from "../Label/Label";
import AlertModal from "./AlertModal";
import { Row, Col, Button } from "react-bootstrap";
import "./AlertListWidget.scss";

const AlertListWidget = ({
  alerts,
  type,
  onAlertClick,
  buildingId,
  isReadOnlyUser
}) => {
  let [modalShow, setModalShow] = useState(false);

  const items = alerts.map((alert, index) => {
    return (
      <Alert
        key={index}
        title={alert.title}
        type={type}
        text={alert.content}
        buildingId={alert.buildingId}
        onAlertClick={onAlertClick}
      ></Alert>
    );
  });

  const renderAlerts = () => {
    if (alerts.length === 0) return <Label subtitle={"Não há alertas"}></Label>;
    else return <List type={type}>{items}</List>;
  };

  const renderButton = buildingId => {
    if (buildingId) {
      return (
        <Button
          id="add-wifi"
          className="button"
          variant="primary"
          onClick={() => setModalShow(true)}
        >
          Adicionar Alerta
        </Button>
      );
    } else {
      return;
    }
  };

  return (
    <div className="alert-widget">
      <Row className="title-container">
        <Col className="list-title">
          <div>Alertas</div>
        </Col>
        {!isReadOnlyUser && renderButton(buildingId)}
        <AlertModal
          buildingId={buildingId}
          show={modalShow}
          setModalShow={setModalShow}
          onHide={() => setModalShow(false)}
        />
      </Row>
      {renderAlerts()}
    </div>
  );
};

AlertListWidget.propTypes = {
  alerts: PropTypes.array,
  type: PropTypes.string,
  onAlertClick: PropTypes.func,
  isReadOnlyUser: PropTypes.bool
};

export default AlertListWidget;
