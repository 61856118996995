import useAlertsQuery from "../queries/useAlertsQuery";
import moment from "moment";
import { formatAlerts } from "../../../../api/Alerts";
import useGetMappedFilters from "./useGetMappedFilters";

const useGetFilteredAlerts = ({ buildingIds = [], limit = 15, page = 0 }) => {
  const start = moment()
    .subtract(30, "days")
    .startOf("day")
    .toDate()
    .getTime();
  const end = moment()
    .endOf("day")
    .toDate()
    .getTime();

  const mappedFilters = useGetMappedFilters();

  const { data: alertsQueryData, refetch } = useAlertsQuery({
    buildingIds: buildingIds,
    start,
    end,
    limit,
    offset: page * limit,
    ...mappedFilters
  });

  const { data: alerts, totalSize } = alertsQueryData || {};

  const buildingId = Array.isArray(buildingIds) ? undefined : buildingIds;

  const formattedAlerts = formatAlerts(alerts, buildingId);

  return { alerts: formattedAlerts, totalSize, refetch };
};

export default useGetFilteredAlerts;
