// import $ from "jquery";

import UFRPEGeoJson from "./campusGeoData/ufrpe.geo.json";
import UFCGGeoJson from "./campusGeoData/ufcg.geo.json";
import PMCGGeoJson from "./campusGeoData/pmcg.geo.json";
import EQUATORIALGeoJson from "./campusGeoData/equatorial.geo.json";
import UEPBGeoJson from "./campusGeoData/uepb.geo.json";
import DEMOGeoJson from "./campusGeoData/demo.geo.json";
import LITECAMPUSGeoJson from "./campusGeoData/litecampus.geo.json";

// import alt_favicon from "../assets/img/alt_favicon.ico";

let geoData;
let zoomCampus = 17;
let defaultCenterPoint;

switch (process.env.REACT_APP_CAMPUS) {
  case "UFCG":
    geoData = UFCGGeoJson;
    defaultCenterPoint = {
      lat: -7.214125,
      lng: -35.910884
    };
    // $("#favicon").attr("href", alt_favicon);
    document.title = "Dashboard Smartcampus - UFCG";
    break;

  case "UFRPE":
    geoData = UFRPEGeoJson;
    defaultCenterPoint = {
      lat: -8.016885,
      lng: -34.948325
    };
    break;

  case "PMCG":
    geoData = PMCGGeoJson;
    defaultCenterPoint = {
      lat: -7.234125,
      lng: -35.900884
    };
    zoomCampus = 13;
    break;

  case "EQUATORIAL":
    geoData = EQUATORIALGeoJson;
    defaultCenterPoint = {
      lat: -4.006321061392173,
      lng: -42.19863384999999
    };
    zoomCampus = 8;
    break;

  case "UEPB":
    geoData = UEPBGeoJson;
    defaultCenterPoint = {
      lat: -7.208808,
      lng: -35.917359
    };
    break;

  case "DEMO":
    geoData = DEMOGeoJson;
    defaultCenterPoint = {
      lat: -10.6565,
      lng: -51.5702
    };
    zoomCampus = 5;
    break;

  case "LITECAMPUS":
    geoData = LITECAMPUSGeoJson;
    defaultCenterPoint = {
      lat: -10.6565,
      lng: -51.5702
    };
    zoomCampus = 5;
    break;

  default:
    geoData = UFCGGeoJson;
    defaultCenterPoint = {
      lat: -7.214125,
      lng: -35.910884
    };
}

export { geoData, defaultCenterPoint, zoomCampus };
