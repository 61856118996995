import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faToggleOff,
  faEye,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import { authenticateLitecampus } from "../../api/Auth";
import { signIn } from "../../actions/auth";
import "./LoginSmartiks.scss";

const mapStateToProps = state => {
  return {
    user: state.auth
  };
};

const LoginSmartiks = ({ signIn }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  let [loginInfo, setLoginInfo] = useState({
    login: "",
    password: "",
    remember: false,
    token: ""
  });

  const onChange = (field, event) => {
    setLoginInfo({ ...loginInfo, [field]: event.target.value });
  };

  const handleRememberChange = () => {
    setLoginInfo({ ...loginInfo, remember: !loginInfo.remember });
  };

  const onSubmit = async event => {
    event.preventDefault();
    const response = await authenticateLitecampus(loginInfo);
    if (response.success) {
      setShowErrorMessage(false);
      const user = {
        id: response.user.id,
        userEmail: response.user.email,
        isAdmin: true,
        isPublic: true,
        isTester: false,
        login: response.user.login,
        timestamp: new Date().getTime()
      };
      signIn(user);
    } else {
      setShowErrorMessage(true);
    }
  };

  return (
    <Row fluid="true" className="login-page-wrapper">
      <Col
        xs={{ span: 12 }}
        md={{ span: 6, offset: 6 }}
        className="login-container"
      >
        <Row className="login-row">
          <Col md={{ span: 12 }} className="login-top-logo"></Col>
          <Form onSubmit={onSubmit} className="login-form-wrapper">
            {showErrorMessage && (
              <div className="login-form-error-msg">
                <span>Login e/ou senha inválido(s).</span>
                <div
                  className="hide-error-msg-btn"
                  onClick={() => setShowErrorMessage(false)}
                >
                  ×
                </div>
              </div>
            )}
            <Form.Group className="login-form-row" controlId="formBasicEmail">
              <Form.Control
                required
                type="email"
                placeholder="EMAIL"
                onChange={e => onChange("login", e)}
              />
            </Form.Group>
            <InputGroup className="login-form-row">
              <Form.Control
                required
                type={showPassword ? "text" : "password"}
                placeholder="SENHA"
                onChange={e => onChange("password", e)}
              />
              <InputGroup.Text className="show-pswd-btn">
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </InputGroup.Text>
            </InputGroup>
            <Form.Group className="login-check">
              <span> CONTINUAR CONECTADO </span>
              <FontAwesomeIcon
                icon={loginInfo.remember ? faToggleOn : faToggleOff}
                size="2x"
                onClick={() => handleRememberChange()}
              />
            </Form.Group>
            <div className="login-btn-row">
              <Button variant="dark" type="submit" className="login-button">
                Entrar
              </Button>
            </div>
            <div
              className="login-form-text-row"
              onClick={() =>
                (location.href = `${process.env.REACT_APP_ENV}/#/mail`)
              }
            >
              Esqueceu a senha?
            </div>
            <div
              className="login-form-text-row"
              onClick={() =>
                (location.href = `${process.env.REACT_APP_ENV}/#/registration`)
              }
            >
              Realizar cadastro
            </div>
          </Form>
          <Col md={{ span: 12 }} className="login-bottom-logo"></Col>
        </Row>
      </Col>
    </Row>
  );
};

LoginSmartiks.propTypes = {
  signIn: PropTypes.func
};

export default connect(mapStateToProps, { signIn })(LoginSmartiks);
