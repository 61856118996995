import * as React from "react";
import { Col } from "react-bootstrap";

const ActivePowerIcon = ({ isActive, ...props }) => (
  <Col xs={5} className="equatorial-measurement-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <g data-name="Grupo 2387">
        <g fill="#fff" stroke="#5d5d5d" data-name="Elipse 2">
          <circle cx={16} cy={16} r={16} stroke="none" />
          <circle
            cx={16}
            cy={16}
            r={15.5}
            fill={isActive ? "#5D5D5D" : "none"}
          />
        </g>
        <path
          fill={isActive ? "white" : "#5D5D5D"}
          stroke="rgba(0,0,0,0)"
          d="M23.397 24.655 15.771 9.069 8.162 24.298a.544.544 0 1 1-.972-.489l8.101-16.211a.545.545 0 0 1 .732-.24.532.532 0 0 1 .245.24l8.108 16.57a.546.546 0 0 1-.49.788.544.544 0 0 1-.489-.301Zm-7.8.07c-.007 0-1.948-1.128-2.151-1.242a.37.37 0 0 1-.186-.322v-2.484a.353.353 0 0 1 .05-.179.365.365 0 0 1 .137-.137c.2-.114 2.144-1.24 2.151-1.24a.347.347 0 0 1 .18-.051.377.377 0 0 1 .186.051l2.15 1.24a.361.361 0 0 1 .135.137.341.341 0 0 1 .051.181v2.484a.351.351 0 0 1-.051.185.359.359 0 0 1-.135.137c-.2.114-2.146 1.242-2.15 1.242a.39.39 0 0 1-.186.05.358.358 0 0 1-.18-.052Z"
          data-name="Uni\xE3o 2"
        />
      </g>
    </svg>
  </Col>
);
export default ActivePowerIcon;
