import React from "react";

import "../../Dashboard.scss";
import "../Energy/Energy.scss";
import EquatorialEnergyDetailed from "./EquatorialEnergyDetailed";
import useSelectedAreaStore from "../../../components/Dashboard/stores/useSelectedAreaStore";
import EquatorialEnergyWidgets from "./EquatorialEnergyWidgets";

const EquatorialEnergy = ({ admin, ...props }) => {
  const hasSelectedArea = useSelectedAreaStore(state => state.hasSelectedArea);

  if (hasSelectedArea) return <EquatorialEnergyWidgets admin={admin} />;

  return <EquatorialEnergyDetailed {...props} />;
};

export default EquatorialEnergy;
