import useTimeTypeStore from "../../../../components/Dashboard/stores/useTimeTypeStore";
import useAllDataForSelectedMeasurement from "./useAllDataForSelectedMeasurement";

const useAllDataForSelectedBuilding = () => {
  const data = useAllDataForSelectedMeasurement();
  const timeType = useTimeTypeStore(state => state.timeType);

  const timeKey = timeType === "today" ? "day" : "month";

  return data[timeKey];
};

export default useAllDataForSelectedBuilding;
