import { create } from "zustand";

const useSelectedAreaStore = create(set => ({
  selectedArea: {
    buildingId: "",
    name: ""
  },
  hasSelectedArea: false,
  setSelectedArea: selectedArea =>
    set({ selectedArea, hasSelectedArea: !!selectedArea.buildingId }),
  resetSelectedArea: () =>
    set({ selectedArea: { buildingId: "", name: "" }, hasSelectedArea: false })
}));

export default useSelectedAreaStore;
