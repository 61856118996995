import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faToggleOff,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import swal from "@sweetalert/with-react";
import { updateRNPUser } from "../../api/Users";
import "./BuildingsModal.scss";

const BuildingsModal = ({
  user,
  show,
  setModalShow,
  onHide,
  buildings,
  authNumber,
  setAuthNumber
}) => {
  const [authorizedBuildings, setAuthorizedBuildings] = useState(
    user.authorizedBuildings ? user.authorizedBuildings : []
  );

  const readOnlyKey = "RNP_READ_ONLY_USER";

  const [isReadOnlyUser, setIsReadOnlyUser] = useState(
    user.roles.includes(readOnlyKey)
  );

  const hasBuildingAuthorization = buildingId => {
    return authorizedBuildings
      ? authorizedBuildings.includes(buildingId)
      : false;
  };

  const orderByActiveBuildings = (a, b) =>
    hasBuildingAuthorization(b.buildingId) -
    hasBuildingAuthorization(a.buildingId);

  let orderedBuildings = buildings;

  const [filteredBuildings, setFilteredBuildings] = useState(
    orderedBuildings.sort(orderByActiveBuildings)
  );

  const [searchBuilding, setSearchBuilding] = useState("");

  useEffect(() => {
    setFilteredBuildings(filteredBuildings.sort(orderByActiveBuildings));
  }, [authNumber]);

  useEffect(() => {
    let data = [...buildings];

    if (searchBuilding !== "") {
      data = data.filter(building =>
        building.name.toLowerCase().includes(searchBuilding.toLowerCase())
      );
    }

    setFilteredBuildings(data);
  }, [searchBuilding]);

  const toggleBuildingAuthorization = buildingId => {
    let newAuthBuildings = [...authorizedBuildings];
    if (hasBuildingAuthorization(buildingId)) {
      newAuthBuildings.splice(newAuthBuildings.indexOf(buildingId), 1);
    } else {
      newAuthBuildings.push(buildingId);
    }
    setAuthorizedBuildings(newAuthBuildings);
  };

  const onSubmit = async () => {
    let roles = [...user.roles];
    if (isReadOnlyUser !== user.roles.includes(readOnlyKey)) {
      if (isReadOnlyUser) roles.push(readOnlyKey);
      else roles.splice(user.roles.indexOf(readOnlyKey), 1);
    }
    updateRNPUser(user, authorizedBuildings, roles)
      .then(() => {
        swal(
          "Acesso atualizado",
          `O acesso do usuário ${user.firstName} ${user.lastName} foi atualizado com sucesso.`,
          "success"
        ).then(() => {
          setModalShow(false);
          setAuthNumber(authorizedBuildings ? authorizedBuildings.length : 0);
        });
      })
      .catch(() => {
        swal(
          "Ops... Algo deu errado",
          "Não foi possível atualizar o acesso do usuário",
          "error"
        );
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="access-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Gerenciar Acesso - {user.firstName} {user.lastName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className={"read-only-user-row"}>
          <Col
            md={3}
            className={"text-wrapper " + (isReadOnlyUser ? "on" : "off")}
          >
            <div className={"text"}>Usuário read-only</div>
          </Col>
          <Col md={1} className={"switcher " + (isReadOnlyUser ? "on" : "off")}>
            <FontAwesomeIcon
              icon={isReadOnlyUser ? faToggleOn : faToggleOff}
              size="2x"
              onClick={() => setIsReadOnlyUser(!isReadOnlyUser)}
            />
          </Col>
        </Row>
        <Row className="search-row">
          <div className="buildings-list-search">
            <div className="buildings-list-search-label">
              <FontAwesomeIcon
                icon={faSearch}
                className="buildings-list-search-icon"
              />
              <input
                className="buildings-list-search-input"
                name="search-building"
                type="text"
                value={searchBuilding}
                onChange={e => setSearchBuilding(e.target.value)}
              />
            </div>
          </div>
        </Row>
        <div className="buildings-list">
          {filteredBuildings.map((building, index) => (
            <Row
              key={index}
              className={
                "building-header " +
                (hasBuildingAuthorization(building.buildingId) ? "on" : "off")
              }
            >
              <Col
                md={6}
                className="text"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}
              >
                {building.name}
              </Col>
              <Col md={1} className="switcher">
                <FontAwesomeIcon
                  icon={
                    hasBuildingAuthorization(building.buildingId)
                      ? faToggleOn
                      : faToggleOff
                  }
                  size="2x"
                  onClick={() =>
                    toggleBuildingAuthorization(building.buildingId)
                  }
                />
              </Col>
            </Row>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="update-button"
          variant="primary"
          onClick={() => onSubmit()}
        >
          Atualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

BuildingsModal.propTypes = {
  user: PropTypes.object,
  show: PropTypes.bool,
  setModalShow: PropTypes.func,
  onHide: PropTypes.func,
  buildings: PropTypes.array
};

export default BuildingsModal;
