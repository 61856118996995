const polygonStyle = {
  color: "#2F9FD6",
  weight: 1,
  fillColor: "#2F9FD6",
  fillOpacity: 1,
  strokeColor: "transparent"
};

const polygonAlertStyle = {
  color: "red",
  weight: 1,
  fillColor: "red",
  fillOpacity: 1,
  strokeColor: "transparent"
};

const polygonWifiStyle = {
  color: "#7e0cf5",
  weight: 1,
  fillColor: "#7e0cf5",
  fillOpacity: 1,
  strokeColor: "transparent"
};

const available = {
  color: "#2F9FD6",
  weight: 1,
  fillColor: "#2F9FD6",
  fillOpacity: 1,
  strokeColor: "transparent"
};

const tweakedAvailable = color => ({
  color: color,
  weight: 1,
  fillColor: color,
  fillOpacity: 1,
  strokeColor: "transparent"
});

const unavailableStyle = {
  color: "#AAAAAA",
  weight: 1,
  fillColor: "#AAAAAA",
  fillOpacity: 1,
  strokeColor: "transparent",
  cursor: "initial"
};

const wifiStyle = {
  color: "#AAAAAA",
  weight: 1,
  fillColor: "#AAAAAA",
  fillOpacity: 1,
  strokeColor: "transparent"
};

const mobsenseStyle = {
  color: "#F35456",
  fillColor: "#F35456",
  fillOpacity: 0.4,
  strokeColor: "transparent"
};

const actuatorsStyle = {
  color: "#00796b",
  weight: 1,
  fillColor: "#00796b",
  fillOpacity: 1,
  strokeColor: "transparent"
};

export {
  unavailableStyle,
  polygonAlertStyle,
  polygonStyle,
  polygonWifiStyle,
  available,
  wifiStyle,
  mobsenseStyle,
  tweakedAvailable,
  actuatorsStyle
};
