import React from "react";
import "./Label.scss";
import PropTypes from "prop-types";

const Label = ({ title, subtitle, type, onClick }) => (
  <div
    className="label"
    onClick={onClick}
    style={onClick && { cursor: "pointer" }}
  >
    <p className={"title " + type}>{title}</p>
    <span className="subtitle">{subtitle}</span>
  </div>
);

Label.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string
};

export default Label;
