import { combineReducers } from "redux";

import authReducer from "./authReducer";
import wifiReducer from "./wifiReducer";
import dataReducer from "./dataReducer";
import mobsenseReducer from "./mobsenseReducer";

export default combineReducers({
  auth: authReducer,
  wifi: wifiReducer,
  data: dataReducer,
  mobsense: mobsenseReducer
});
