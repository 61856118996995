import {
  REQUEST_ALL_DATA,
  ALL_DATA_SUCCESS,
  ALL_DATA_FAILURE,
  REQUEST_BUILDING_DATA,
  BUILDING_DATA_SUCCESS,
  BUILDING_DATA_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  all: {
    areas: [],
    consumption: 0,
    chart: [],
    alerts: [],
    actuators: [],
    users: []
  },
  building: {
    id: "",
    data: {
      demand: {},
      consumption: 0,
      compare: {},
      chart: [],
      alerts: [],
      actuators: []
    }
  }
};

let allDataSuccessID = 0;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_ALL_DATA:
      return {
        ...state,
        loading: true
      };
    case ALL_DATA_SUCCESS:
      if (action.id >= allDataSuccessID) {
        allDataSuccessID = action.id;
        return { ...state, loading: false, all: action.payload };
      } else {
        return state;
      }
    case ALL_DATA_FAILURE:
      return { ...state, loading: false, all: action.payload };
    case REQUEST_BUILDING_DATA:
      return {
        ...state,
        loading: true
      };
    case BUILDING_DATA_SUCCESS:
      return { ...state, loading: false, building: { data: action.payload } };
    case BUILDING_DATA_FAILURE:
      return { ...state, loading: false, building: { data: action.payload } };
    default:
      return state;
  }
};
