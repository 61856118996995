import React, { useState, useEffect } from "react";
import { Service, CategoryService } from "../../../api/Service";
import "./SettingsTab.scss";

const SelectCategory = ({ building }) => {
  const [categories, setCategories] = useState({
    "0": "Categoria"
  });

  const [category, setCategory] = useState(
    building.category === undefined ||
      building.category === null ||
      building.category === ""
      ? "Categoria"
      : categories[building.category]
  );

  const fetchData = () => {
    Service.get("/building/categories").then(({ data }) => {
      const results = {};
      data.forEach(value => {
        results[value.id] = value.categoryName;
      });
      setCategories({ ...categories, ...results });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCategory(
      building.category === undefined ||
        building.category === null ||
        building.category === ""
        ? "Categoria"
        : categories[building.category]
    );
  }, [categories]);

  function handleChange(bId, bCategory) {
    CategoryService.put(`/building/${bId}/category?category=${bCategory}`).then(
      () => {
        setCategory(bCategory);
        building.category = Object.keys(categories).find(
          key => categories[key] === bCategory
        );
      }
    );
  }

  return (
    <select
      key={building.id}
      value={category}
      onChange={e => handleChange(building.id, e.target.value)}
    >
      {Object.keys(categories).map(key => {
        return (
          <option key={key} value={categories[key]}>
            {categories[key]}
          </option>
        );
      })}
    </select>
  );
};

const Areas = ({ buildings }) => {
  return (
    <div className="area-list">
      <section className="area-list-header">
        <div className="name">Nome</div>
        <div className="class">Classe</div>
      </section>
      <section className="area-list-content">
        {buildings.map((building, index) => (
          <section key={index} className="building-header">
            <div className="name">{building.name}</div>
            <div className="class">
              <SelectCategory building={building} />
            </div>
          </section>
        ))}
      </section>
    </div>
  );
};

export default Areas;
