const baseURL = `${process.env.REACT_APP_ENV}service/rest`;

const defaultQueryFn = async ({ queryKey }) => {
  const [url, params] = queryKey;
  return fetch(
    `${baseURL}${url}?${new URLSearchParams(params).toString()}`
  ).then(res => res.json());
};

export default defaultQueryFn;
