import { createSelector } from "reselect";

const getAllBuildingsDataState = state => (state.data ? state.data.all : []);
const getBuildingDataState = state =>
  state.data ? state.data.building.data : {};
const getLoadingState = state => (state.data ? state.data.loading : false);

export const allBuildingsData = createSelector(
  [getAllBuildingsDataState],
  data => data
);

export const buildingData = createSelector(
  [getBuildingDataState],
  data => data
);

export const isLoading = createSelector([getLoadingState], loading => loading);
